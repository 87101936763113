import { NgScrollbar } from 'ngx-scrollbar';
import { CommonService } from '@core/services';
import { filter, Subscription, tap } from 'rxjs';
import { routerAnimation } from '@shared/animations';
import { Router, NavigationEnd } from '@angular/router';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';

@Component({
	selector: 'crave-admin-layout',
	templateUrl: './crave-admin-layout.component.html',
	styleUrls: ['./crave-admin-layout.component.css'],
	animations: [routerAnimation]
})
export class CraveAdminLayoutComponent implements OnInit, OnDestroy {
	constructor(
		private _router: Router,
		private _commonService: CommonService
	) {}

	private subscriptions: Subscription[] = [];
	@ViewChild(NgScrollbar) scrollbarRef!: NgScrollbar;

	ngOnInit(): void {
		this.routeHandler();
		this.getElementToScroll();
	}

	/**
	 * *Scrolls to an element
	 *
	 * @date 24 December 2022
	 * @developer Rahul Kundu
	 */
	private getElementToScroll(): void {
		this.subscriptions.push(
			this._commonService.elementToScrollSource$.subscribe({
				next: (element) => {
					if (!!element) {
						this.scrollbarRef.scrollToElement(element, {
							duration: 400
						});
					}
				}
			})
		);
	}

	/**
	 * *Updating scroll position to top on route change
	 *
	 * @date 06 December 2022
	 * @developer Rahul Kundu
	 */
	private routeHandler() {
		this.subscriptions.push(
			this._router.events
				.pipe(
					filter((event) => event instanceof NavigationEnd),
					filter(() => !!this.scrollbarRef),
					tap(() =>
						this.scrollbarRef.scrollTo({ top: 0, duration: 0 })
					)
				)
				.subscribe()
		);
	}

	ngOnDestroy(): void {
		this.subscriptions.forEach((subscription) =>
			subscription.unsubscribe()
		);
	}
}
