import {
	OnInit,
	Renderer2,
	Directive,
	OnDestroy,
	ElementRef
} from '@angular/core';

@Directive({
	selector: '[layoutBreakpoint]'
})
export class LayoutBreakpointDirective implements OnInit, OnDestroy {
	constructor(
		private _renderer: Renderer2,
		private _elementRef: ElementRef
	) {}

	private resizeListener!: Function;

	ngOnInit() {
		this.resizeListener = this._renderer.listen(window, 'resize', () => {
			this.applyClassBasedOnScreenWidth();
		});

		this.applyClassBasedOnScreenWidth();
	}

	/**
	 * *Apply classes to the element based on the screen width.
	 *
	 * @date 04 July 2023
	 * @developer Rahul Kundu
	 */
	private applyClassBasedOnScreenWidth() {
		const windowWidth = window.innerWidth;
		const classList = this._elementRef.nativeElement.classList;

		// Remove all existing screen width classes
		classList.remove(
			'crave-admin-layout-xxl',
			'crave-admin-layout-xl',
			'crave-admin-layout-lg',
			'crave-admin-layout-md',
			'crave-admin-layout-sm'
		);

		// Add the appropriate class based on screen width
		if (windowWidth >= 1400) {
			classList.add('crave-admin-layout-xxl');
		} else if (windowWidth >= 1200) {
			classList.add('crave-admin-layout-xl');
		} else if (windowWidth >= 992) {
			classList.add('crave-admin-layout-lg');
		} else if (windowWidth >= 768) {
			classList.add('crave-admin-layout-md');
		} else {
			classList.add('crave-admin-layout-sm');
		}
	}

	ngOnDestroy() {
		// Clean up the resize listener
		if (this.resizeListener) {
			this.resizeListener();
		}
	}
}
