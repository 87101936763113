import { Injectable } from '@angular/core';

@Injectable()
export class WindowRefService {
	/**
	 * *method to get window
	 *
	 * @date 05 December 2022
	 * @developer Rahul Kundu
	 */
	get nativeWindow(): { [key: string]: any } {
		return getWindow();
	}
}

/**
 * *Getting window referance from window object
 *
 * @date 05 December 2022
 * @developer Rahul Kundu
 */
function getWindow(): any {
	return window;
}
