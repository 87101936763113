import { of } from 'rxjs';
import { environment } from '@env/environment';
import { LibraryLoaderService, WindowRefService } from './services';
import { EnsureModuleLoadedOnceGuard } from './ensureModuleLoadedOnceGuard';
import { APP_INITIALIZER, NgModule, Optional, SkipSelf } from '@angular/core';

@NgModule({
	providers: [
		{
			multi: true,
			provide: APP_INITIALIZER,
			useFactory: (
				_window: WindowRefService,
				_libraryLoader: LibraryLoaderService
			) => {
				return () => {
					const googleMapsKey = environment.googleApiKey;
					const mapsEndpoint = `https://maps.googleapis.com/maps/api/js?&libraries=places,drawing,geometry&key=${googleMapsKey}&callback=initMap&loading=async`;

					_window.nativeWindow['initMap'] = () => {};

					_libraryLoader.loadLibary(mapsEndpoint, 'script', {
						async: true
					});

					return of(true);
				};
			},
			deps: [WindowRefService, LibraryLoaderService]
		}
	]
})
export class AppInitializerModule extends EnsureModuleLoadedOnceGuard {
	/**
	 * !Ensuring that CoreModule is only loaded into AppModule
	 * *Looks for the module in the parent injector to see if it's already been loaded (only load once)
	 *
	 * @param module
	 * @date: 26 August 2022
	 * @developer: Rahul Kundu
	 */
	constructor(@Optional() @SkipSelf() module: AppInitializerModule) {
		super(module);
	}
}
