import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { AuthenticationService } from '@core/authentication';
import {
	HttpEvent,
	HttpRequest,
	HttpHandler,
	HttpInterceptor
} from '@angular/common/http';

@Injectable()
export class HttpAuthorizationHeaderInterceptor implements HttpInterceptor {
	constructor(private _authService: AuthenticationService) {}

	/**
	 * *Adding Authorization token in header
	 *
	 * @date 05 December 2022
	 * @developer Rahul Kundu
	 */
	public intercept(
		request: HttpRequest<any>,
		next: HttpHandler
	): Observable<HttpEvent<any>> {
		const headersConfig: any = {};

		/**
		 * If token found setting it in header
		 */
		const token: string | null = this._authService.getAccessToken();
		if (!!token) {
			headersConfig['Authorization'] = 'Bearer ' + token;
		}

		const HTTPRequest = request.clone({ setHeaders: headersConfig });
		return next.handle(HTTPRequest);
	}
}
