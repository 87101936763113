import { NgModel } from '@angular/forms';
import { debounceTime, distinctUntilChanged, skip, Subscription } from 'rxjs';
import {
	Input,
	OnInit,
	Output,
	OnDestroy,
	Directive,
	EventEmitter
} from '@angular/core';

@Directive({
	selector: '[ngModelDebounceChange]'
})
export class NgModelDebounceChangeDirective implements OnDestroy, OnInit {
	constructor(private ngModel: NgModel) {}

	@Input() ngModelDebounceTime = 600;
	private subscription!: Subscription;
	@Output() ngModelDebounceChange: EventEmitter<string> =
		new EventEmitter<string>();

	/**
	 * *Emitting ng model change using debounce search
	 *
	 * @date 25 December 2022
	 * @developer Rahul Kundu
	 */
	ngOnInit(): void {
		this.subscription = this.ngModel.control.valueChanges
			.pipe(
				skip(1),
				distinctUntilChanged(),
				debounceTime(this.ngModelDebounceTime)
			)
			.subscribe({
				next: (value: string) => {
					this.ngModelDebounceChange.emit(value);
				}
			});
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}
}
