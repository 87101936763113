import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'nameInitials'
})
export class NameInitialsPipe implements PipeTransform {
	/**
	 * *Pipe to create name initials from  first name and last name
	 *
	 * @returns string
	 * @date 05 December 2022
	 * @developer Rahul Kundu
	 */
	public transform(firstName: string, lastname: string): string {
		return (
			firstName.charAt(0).toUpperCase() + lastname.charAt(0).toUpperCase()
		);
	}
}
