import { Observable } from 'rxjs';
import { IPlace } from '@shared/models';
import cityTimezones from 'city-timezones';
import { Injectable, NgZone } from '@angular/core';

@Injectable()
export class GoogleAutocompleteService {
	/**
	 * The underlying google.maps.places.AutocompleteService object
	 *
	 * @see https://developers.google.com/maps/documentation/javascript/reference/places-autocomplete-service#AutocompleteService
	 */
	private readonly _autoComplete =
		new google.maps.places.AutocompleteService();

	/**
	 * The underlying google.maps.places.PlacesService object
	 *
	 * @see https://developers.google.com/maps/documentation/javascript/reference/places-service#PlacesService
	 */
	private readonly _place = new google.maps.places.PlacesService(
		document.createElement('div')
	);

	constructor(private _ngZone: NgZone) {}

	/**
	 * Wrapper for `autocomplete` request, that is being sent to Google servers.
	 * Fetching places from autocomplete service
	 *
	 * @see https://developers.google.com/maps/documentation/javascript/reference/places-autocomplete-service#AutocompleteService.getPlacePredictions
	 * @returns Observable<google.maps.places.AutocompletePrediction[]>
	 * @date 16 December 2022
	 * @developer Rahul Kundu
	 */
	public getPredictions(
		request: google.maps.places.AutocompletionRequest
	): Observable<google.maps.places.AutocompletePrediction[]> {
		return new Observable<google.maps.places.AutocompletePrediction[]>(
			(observer) => {
				this._autoComplete.getPlacePredictions(
					{
						...request,
						types: ['address']
					},
					(results, status) => {
						// need to manually trigger ngZone because "geocode" callback is not picked up properly by Angular
						this._ngZone.run(() => {
							if (
								status ===
								google.maps.places.PlacesServiceStatus.OK
							) {
								// if status is "OK", the response contains a valid GeocoderResponse.
								observer.next(!!results ? results : []);
								observer.complete();
							} else if (
								status ===
									google.maps.places.PlacesServiceStatus
										.ZERO_RESULTS ||
								status ===
									google.maps.places.PlacesServiceStatus
										.NOT_FOUND ||
								status ===
									google.maps.places.PlacesServiceStatus
										.OVER_QUERY_LIMIT
							) {
								observer.next([]);
								observer.complete();
							} else {
								observer.error(status);
							}
						});
					}
				);
			}
		);
	}

	/**
	 * Wrapper for `place` request, that is being sent to Google servers.
	 * Fetching a place detail from places service
	 *
	 * @see https://developers.google.com/maps/documentation/javascript/reference/places-service#PlacesService.getDetails
	 * @returns Observable<google.maps.GeocoderResult[]>
	 * @date 16 December 2022
	 * @developer Rahul Kundu
	 */
	public getPredictionDetail(
		request: google.maps.places.PlaceDetailsRequest
	) {
		return new Observable<google.maps.places.PlaceResult | null>(
			(observer) => {
				this._place.getDetails(request, (result, status) => {
					// need to manually trigger ngZone because "geocode" callback is not picked up properly by Angular
					this._ngZone.run(() => {
						if (
							status === google.maps.places.PlacesServiceStatus.OK
						) {
							observer.next(result);
							observer.complete();
						} else if (
							status ===
								google.maps.places.PlacesServiceStatus
									.ZERO_RESULTS ||
							status ===
								google.maps.places.PlacesServiceStatus
									.NOT_FOUND ||
							status ===
								google.maps.places.PlacesServiceStatus
									.OVER_QUERY_LIMIT
						) {
							observer.next(null);
							observer.complete();
						} else {
							observer.error(status);
						}
					});
				});
			}
		);
	}

	/**
	 * *Formats a place details
	 *
	 * @param result
	 * @returns IPlace
	 * @date 16 December 2022
	 * @developer Rahul Kundu
	 */
	public formatPlaceResult(result: google.maps.places.PlaceResult): IPlace {
		let zone: string = '';
		let city: string = '';
		let latitude!: number;
		let longitude!: number;
		let state: string = '';
		let street: string = '';
		let country: string = '';
		let address: string = '';
		let locality: string = '';
		let timezone: string = '';
		let stateCode: string = '';
		let postalCode: string = '';
		let streetNumber: string = '';

		const addressComponents = result.address_components;

		if (!!addressComponents) {
			addressComponents.forEach(
				(component: google.maps.GeocoderAddressComponent) => {
					component.types.forEach((type: string) => {
						switch (type) {
							case 'neighborhood':
								zone = component.long_name;
								break;
							case 'locality':
								city = component.long_name;
								break;
							case 'country':
								country = component.long_name;
								break;
							case 'route':
								street = component.short_name;
								break;
							case 'sublocality_level_1':
								locality = component.long_name;
								break;
							case 'administrative_area_level_1':
								state = component.long_name;
								stateCode = component.short_name;
								break;
							case 'postal_code':
								postalCode = component.long_name;
								break;
							case 'street_number':
								streetNumber = component.short_name;
								break;
						}
					});
				}
			);

			if (!!result.geometry && result.geometry.location) {
				latitude = result.geometry.location.lat();
				longitude = result.geometry.location.lng();
			}

			if (!!city) {
				const timeZoneLookUps = cityTimezones.lookupViaCity(city);
				const filteredTimezone = timeZoneLookUps.find(
					(timeZone) => timeZone.country.indexOf(country) !== -1
				);

				timezone = !!filteredTimezone
					? filteredTimezone.timezone
					: 'America/Los_Angeles';
			}

			address = `${streetNumber} ${street}, ${city}, ${stateCode} ${postalCode}, ${country}`;
		}

		const place: IPlace = {
			zone: !!zone ? zone : null,
			city: !!city ? city : null,
			state: !!state ? state : null,
			street: !!street ? street : null,
			country: !!country ? country : null,
			address: !!address ? address : null,
			latitude: !!latitude ? latitude : null,
			timezone: !!timezone ? timezone : null,
			longitude: !!longitude ? longitude : null,
			state_code: !!stateCode ? stateCode : null,
			postalCode: !!postalCode ? postalCode : null,
			streetNumber: !!streetNumber ? streetNumber : null,
			placeId: !!result.place_id ? result.place_id : null,
			description: !!result.formatted_address
				? result.formatted_address
				: null
		};

		return place;
	}
}
