import {
	OnChanges,
	Directive,
	ElementRef,
	Input,
	SimpleChanges
} from '@angular/core';

@Directive({
	selector: 'input[focusControl]'
})
export class FocusControlDirective implements OnChanges {
	constructor(public _el: ElementRef<HTMLElement>) {}

	@Input('focusControl') isFocused: boolean = false;

	/**
	 * *Directive to focus input
	 *
	 * @date 31 January 2023
	 * @developer Rahul Kundu
	 */
	ngOnChanges(changes: SimpleChanges): void {
		if (changes['isFocused'].currentValue) {
			setTimeout(() => {
				this._el.nativeElement.focus();
			}, 0);
		} else {
			this._el.nativeElement.blur();
		}
	}
}
