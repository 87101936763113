import moment from 'moment';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'momentTimeAgo'
})
export class MomentTimeAgoPipe implements PipeTransform {
	/**
	 * *Finds time ago difference between two dates in human readble format
	 *
	 * @param endDate
	 * @param startDate
	 * @returns string
	 */
	public transform(
		endDate: Date | string,
		startDate?: Date | string
	): string {
		const endMoment = moment(endDate);
		const startMoment = !!startDate ? moment(startDate) : moment();
		const differenceInDays = endMoment.diff(startMoment, 'days');

		if (differenceInDays > 10) {
			return endMoment.format('DD MMM, Y');
		}

		let timeago = moment(startMoment).fromNow();
		return timeago === 'a few seconds ago' ? 'just now' : timeago;
	}
}
