import {
	Input,
	Directive,
	Renderer2,
	ElementRef,
	HostListener,
	AfterViewInit
} from '@angular/core';

@Directive({
	selector: '[autocompleteOff]'
})
export class AutocompleteOffDirective implements AfterViewInit {
	constructor(private _el: ElementRef, private _renderer: Renderer2) {}

	@Input('autocompleteOff') autocompleteType: string = '';

	/**
	 * *Disabling autocomoplete of an Inout file
	 *  - setting a random number in name and autocomplete of input
	 *
	 * Example:
	 * [autocompleteOff]="'off'"
	 *
	 * @date 05 December 2022
	 * @developer Rahul Kundu
	 */
	ngAfterViewInit(): void {
		if (this.autocompleteType == 'off') {
			this.removeAutoComplete();
		}
	}

	/**
	 * *Removes input element autocomplete on blur
	 *
	 * @param event
	 * @date 05 December 2022
	 * @developer Rahul Kundu
	 */
	@HostListener('blur', ['$event'])
	public onValueChange(event: Event): void {
		this.removeAutoComplete();
	}

	/**
	 * *Removes input element autocomplete
	 *
	 * @date 05 December 2022
	 * @developer Rahul Kundu
	 */
	private removeAutoComplete(): void {
		const randomString = Math.random().toString(36).slice(-6);
		this._renderer.setAttribute(
			this._el.nativeElement,
			'name',
			randomString
		);
		this._renderer.setAttribute(
			this._el.nativeElement,
			'autocomplete',
			randomString
		);
	}
}
