<div class="custom-toast-inner">
	<div class="custom-toast-content">
		<ng-container *ngIf="message">
			<h6>
				{{ message | capitalize }}
			</h6>
		</ng-container>
	</div>
	<ng-container *ngIf="options.closeButton">
		<div class="custom-toast-close-button">
			<a (click)="remove()">
				<span class="material-symbols-rounded"> close </span>
			</a>
		</div>
	</ng-container>
</div>
