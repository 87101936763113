import { Injector, inject } from '@angular/core';
import { Router, CanActivateFn } from '@angular/router';
import { AuthenticationService } from '@core/authentication';

/**
 * *Securing outer pages if logged in
 *
 * @date 13 July 2023
 * @developer Rahul Kundu
 */
export const secureOuterPagesGuard: CanActivateFn = (route, state): boolean => {
	const _injector = inject(Injector);
	const router: Router = _injector.get(Router);
	const authService: AuthenticationService = _injector.get(
		AuthenticationService
	);

	const isAuthenticated = authService.isAuthenticated();

	if (isAuthenticated) {
		const url = route.url[0].toString();
		switch (url) {
			case 'sign-in':
				router.navigate(['/dashboard']);
				return false;
			case 'reset-password':
				router.navigate(['/dashboard']);
				return false;
			case 'forget-password':
				router.navigate(['/dashboard']);
				return false;
			default:
				return true;
		}
	}

	// not logged in, proceed to the requested route
	return true;
};
