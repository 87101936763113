<main class="app-main">
	<router-outlet></router-outlet>
</main>

<!-- progressbar start -->
<ng-progress
	id="progress-bar"
	[meteor]="true"
	[spinner]="false"
	[color]="'#042B5C'"
></ng-progress>
<!-- progressbar end -->

<!-- preloader start -->
<ng-container *ngIf="isLoadingFirstTime">
	<div [@fadeAnimation]="isLoadingFirstTime" class="custom-preloader-outer">
		<div class="custom-preloader-inner">
			<img src="./assets/images/auth-bg.png" alt="" />
			<div class="preloader-brand">
				<img
					alt=""
					width="150"
					height="35"
					src="./assets/images/logo.svg"
				/>
			</div>
		</div>
	</div>
</ng-container>
<!-- preloader end -->

<!-- confirm popup start -->
<p-confirmDialog
	[header]="''"
	#confirmDialog
	[appendTo]="'body'"
	[closeOnEscape]="true"
	[styleClass]="'custom-confirm-dialog'"
	[maskStyleClass]="'custom-confirm-dialog-mask'"
>
	<ng-template pTemplate="header">
		<div class="custom-confirm-dialog-close">
			<a href="javascript:void(0)" (click)="confirmDialog.reject()">
				<span class="material-symbols-rounded"> close </span>
			</a>
		</div>
	</ng-template>
	<ng-template pTemplate="footer">
		<div class="button-group button-group-center">
			<button
				type="button"
				(click)="confirmDialog.accept()"
				class="button button-primary button-medium"
			>
				<p>Confirm</p>
			</button>
			<button
				type="button"
				(click)="confirmDialog.reject()"
				class="button button-outline button-medium"
			>
				<p>Cancel</p>
			</button>
		</div>
	</ng-template>
</p-confirmDialog>
<!-- confirm popup end -->
