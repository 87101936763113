import { Cloudinary } from '@cloudinary/url-gen';
import { environment } from '@env/environment';

export const cloudinaryConfig = new Cloudinary({
	cloud: {
		cloudName: environment.cloudinaryCloudName
	},
	url: {
		secure: true
	}
});
