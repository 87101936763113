import {
	Input,
	OnChanges,
	Directive,
	ElementRef,
	HostListener,
	SimpleChanges
} from '@angular/core';

@Directive({
	selector: '[digitOnly]'
})
export class DigitOnlyDirective implements OnChanges {
	constructor(public _elementRef: ElementRef) {
		this.inputElement = _elementRef.nativeElement;
	}

	@Input() decimal = true;
	@Input() min = -Infinity;
	@Input() max = Infinity;
	@Input() allowPaste = true;
	@Input() isCurrency = false;
	@Input() negativeSign = '-';
	@Input() allowNegatives = false;
	@Input() decimalSeparator = '.';
	@Input() allowKeys: string[] = [];
	@Input() pattern?: string | RegExp;
	@Input() allowMultipleDecimals = false;

	private regex!: RegExp | null;
	private hasNegativeSign = false;
	private hasDecimalPoint = false;
	private inputElement: HTMLInputElement;
	private navigationKeys = [
		'Tab',
		'End',
		'Home',
		'Copy',
		'Enter',
		'Clear',
		'Paste',
		'Escape',
		'Delete',
		'Backspace',
		'ArrowLeft',
		'ArrowRight'
	];

	/**
	 * *Lifecycle hook that is called when one or more input properties of the component change.
	 * *It checks for changes in the 'pattern', 'min', and 'max' input properties and updates the component's internal properties accordingly.
	 * *If 'pattern' changes, it updates the 'regex' property with a new RegExp based on the pattern value.
	 * *If 'min' changes, it attempts to convert the new value to a number and updates the 'min' property accordingly.
	 * *If 'max' changes, it attempts to convert the new value to a number and updates the 'max' property accordingly.
	 * *If the conversion to a number fails, the component sets the 'min' or 'max' property to -Infinity or Infinity, respectively.
	 *
	 * @param {SimpleChanges} changes - The object containing the changed input properties.
	 *
	 * @date 13 March 2023
	 * @developer Rahul Kundu
	 */
	ngOnChanges(changes: SimpleChanges): void {
		if (changes['pattern']) {
			this.regex = this.pattern ? RegExp(this.pattern) : null;
		}

		if (changes['min']) {
			const maybeMin = Number(this.min);
			this.min = isNaN(maybeMin) ? -Infinity : maybeMin;
		}

		if (changes['max']) {
			const maybeMax = Number(this.max);
			this.max = isNaN(maybeMax) ? Infinity : maybeMax;
		}
	}

	/**
	 * *Event listener that is triggered before an input event.
	 *
	 * @param {InputEvent} event - The input event object.
	 * @date 13 March 2023
	 * @developer Rahul Kundu
	 */
	@HostListener('beforeinput', ['$event'])
	onBeforeInput(event: InputEvent): void {
		const inputCharacter = event.data ?? '';

		if (isNaN(Number(inputCharacter))) {
			if (
				this.allowKeys.includes(inputCharacter) ||
				inputCharacter === this.decimalSeparator ||
				(inputCharacter === this.negativeSign && this.allowNegatives)
			) {
				return; // go on
			}

			event.preventDefault();
			event.stopPropagation();
		}
	}

	/**
	 * *Event listener that is triggered on keydown events.
	 *
	 * @param {KeyboardEvent} event - The keyboard event object.
	 *
	 * @date 13 March 2023
	 * @developer Rahul Kundu
	 */
	@HostListener('keydown', ['$event'])
	onKeyDown(event: KeyboardEvent): void {
		const authorizedInputKeys = [...this.navigationKeys, ...this.allowKeys];

		if (
			authorizedInputKeys.indexOf(event.key) > -1 || // Allow: navigation keys: backspace, delete, arrows etc.
			((event.key === 'a' || event.code === 'KeyA') &&
				event.ctrlKey === true) || // Allow: Ctrl+A
			((event.key === 'c' || event.code === 'KeyC') &&
				event.ctrlKey === true) || // Allow: Ctrl+C
			((event.key === 'v' || event.code === 'KeyV') &&
				event.ctrlKey === true) || // Allow: Ctrl+V
			((event.key === 'x' || event.code === 'KeyX') &&
				event.ctrlKey === true) || // Allow: Ctrl+X
			((event.key === 'a' || event.code === 'KeyA') &&
				event.metaKey === true) || // Allow: Cmd+A (Mac)
			((event.key === 'c' || event.code === 'KeyC') &&
				event.metaKey === true) || // Allow: Cmd+C (Mac)
			((event.key === 'v' || event.code === 'KeyV') &&
				event.metaKey === true) || // Allow: Cmd+V (Mac)
			((event.key === 'x' || event.code === 'KeyX') &&
				event.metaKey === true) // Allow: Cmd+X (Mac)
		) {
			// let it happen, don't do anything
			return;
		}

		let newValue = '';

		if (this.decimal && event.key === this.decimalSeparator) {
			if (this.allowMultipleDecimals) {
				// Allow decimal separator when allowMultipleDecimals is true
				this.hasDecimalPoint = true;
				return;
			} else {
				newValue = this.forecastValue(event.key);
				if (newValue.split(this.decimalSeparator).length > 2) {
					// has two or more decimal points
					event.preventDefault();
					return;
				} else {
					this.hasDecimalPoint =
						newValue.indexOf(this.decimalSeparator) > -1;
					return; // Allow: only one decimal point
				}
			}
		}

		if (event.key === this.negativeSign && this.allowNegatives) {
			newValue = this.forecastValue(event.key);
			if (
				newValue.charAt(0) !== this.negativeSign ||
				newValue.split(this.negativeSign).length > 2
			) {
				event.preventDefault();
				return;
			} else {
				this.hasNegativeSign =
					newValue.split(this.negativeSign).length > -1;
				return;
			}
		}

		// Ensure that it is a number and stop the keypress
		if (event.key === ' ' || isNaN(Number(event.key))) {
			event.preventDefault();
			return;
		}

		newValue = newValue || this.forecastValue(event.key);
		// Checking the input pattern RegExp
		if (this.regex) {
			if (!this.regex.test(newValue)) {
				event.preventDefault();
				return;
			}
		}

		// Checking the input min and max value
		// If it is currency inout removing spcial characters
		const newNumber = this.isCurrency
			? new RegExp(/[$₹., ]/g).test(newValue)
				? Number(newValue.substring(1).replace(/,/g, ''))
				: Number(newValue)
			: Number(newValue);

		if (newNumber > this.max || newNumber < this.min) {
			event.preventDefault();
		}
	}

	/**
	 * *Event listener that is triggered on paste events.
	 *
	 * @param {ClipboardEvent} event - The paste event object.
	 *
	 * @date 13 March 2023
	 * @developer Rahul Kundu
	 */
	@HostListener('paste', ['$event'])
	onPaste(event: ClipboardEvent): void {
		if (this.allowPaste === true) {
			let pastedInput: string = '';
			if ((window as { [key: string]: any })['clipboardData']) {
				// Browser is IE
				pastedInput = (window as { [key: string]: any })[
					'clipboardData'
				].getData('text');
			} else if (event.clipboardData && event.clipboardData.getData) {
				// Other browsers
				pastedInput = event.clipboardData.getData('text/plain');
			}

			this.pasteData(pastedInput);
			event.preventDefault();
		} else {
			// this prevents the paste
			event.preventDefault();
			event.stopPropagation();
		}
	}

	/**
	 * *Event listener that is triggered on drop events.
	 *
	 * @param {DragEvent} event - The drop event object.
	 *
	 * @date 13 March 2023
	 * @developer Rahul Kundu
	 */
	@HostListener('drop', ['$event'])
	onDrop(event: DragEvent): void {
		const textData: string = event.dataTransfer?.getData('text') as string;
		this.inputElement.focus();
		this.pasteData(textData);
		event.preventDefault();
	}

	/**
	 * *Processes the pasted content and inserts it into the input element.
	 *
	 * @param {string} pastedContent - The content that was pasted.
	 *
	 * @date 13 March 2023
	 * @developer Rahul Kundu
	 */
	private pasteData(pastedContent: string): void {
		const sanitizedContent = this.sanitizeInput(pastedContent);

		if (
			sanitizedContent.includes(this.negativeSign) &&
			this.hasNegativeSign &&
			!this.getSelection().includes(this.negativeSign)
		) {
			return;
		}

		const pasted = document.execCommand(
			'insertText',
			false,
			sanitizedContent
		);

		if (!pasted) {
			if (this.inputElement.setRangeText) {
				const start: any = this.inputElement;
				const end: any = this.inputElement;
				this.inputElement.setRangeText(
					sanitizedContent,
					start,
					end,
					'end'
				);
				// Angular's Reactive Form relies on "input" event, but on Firefox, the setRangeText method doesn't trigger it
				// so we have to trigger it ourself.
				if (typeof (window as any['InstallTrigger']) !== 'undefined') {
					this.inputElement.dispatchEvent(
						new Event('input', { cancelable: true })
					);
				}
			} else {
				// Browser does not support setRangeText, e.g. IE
				this.insertAtCursor(this.inputElement, sanitizedContent);
			}
		}

		if (this.decimal) {
			this.hasDecimalPoint =
				this.inputElement.value.indexOf(this.decimalSeparator) > -1;
		}
	}

	/**
	 * *Inserts a value at the current cursor position in the input field.
	 *
	 * @param {HTMLInputElement} field - The HTMLInputElement where the value should be inserted.
	 * @param {string} value - The value to be inserted.
	 *
	 * @remarks https://stackoverflow.com/questions/11076975/how-to-insert-text-into-the-textarea-at-the-current-cursor-position
	 *
	 * @date 13 March 2023
	 * @developer Rahul Kundu
	 */
	private insertAtCursor(field: HTMLInputElement, value: string): void {
		const startPos: number = field.selectionStart as number;
		const endPos: number = field.selectionEnd as number;

		field.value =
			field.value.substring(0, startPos) +
			value +
			field.value.substring(endPos, field.value.length);

		const pos = startPos + value.length;
		field.focus();
		field.setSelectionRange(pos, pos);

		this.triggerEvent(field, 'input');
	}

	/**
	 * *Triggers a custom event on the specified element.
	 *
	 * @param {HTMLInputElement} el - The HTMLInputElement on which the event should be triggered.
	 * @param {string} type - The type of the event to be triggered.
	 *
	 * @date 13 March 2023
	 * @developer Rahul Kundu
	 */
	private triggerEvent(el: HTMLInputElement, type: string): void {
		if ('createEvent' in document) {
			// modern browsers, IE9+
			const e = document.createEvent('HTMLEvents');
			e.initEvent(type, false, true);
			el.dispatchEvent(e);
		}
	}

	/**
	 * *Sanitizes the input by removing unwanted characters.
	 *
	 * @param {string} input - The input string to be sanitized.
	 * @returns The sanitized input string.
	 *
	 * @date 13 March 2023
	 * @developer Rahul Kundu
	 */
	private sanitizeInput(input: string): string {
		let regex!: RegExp;
		let result!: string;

		const allowedKeysRegex = this.getAllowedKeysRegExp();
		const negativeSignRegex = this.getNegativeSignRegExp();

		if (this.decimal && this.isValidDecimal(input)) {
			regex = new RegExp(
				`${negativeSignRegex}[^0-9${this.decimalSeparator}${allowedKeysRegex}]`,
				'g'
			);
		} else {
			regex = new RegExp(
				`${negativeSignRegex}[^0-9${allowedKeysRegex}]`,
				'g'
			);
		}

		result = input.replace(regex, '');

		const maxLength = this.inputElement.maxLength;

		if (maxLength > 0) {
			// the input element has maxLength limit
			const allowedLength =
				maxLength -
				this.inputElement.value.length +
				(result.includes(`${this.negativeSign}`) ? 1 : 0);
			result =
				allowedLength > 0 ? result.substring(0, allowedLength) : '';
		}

		return result;
	}

	/**
	 * *Generates a regular expression pattern for the negative sign.
	 *
	 * @returns The regular expression pattern for the negative sign.
	 *
	 * @date 13 March 2023
	 * @developer Rahul Kundu
	 */
	private getNegativeSignRegExp(): string {
		return this.allowNegatives &&
			(!this.hasNegativeSign ||
				this.getSelection().includes(this.negativeSign))
			? `(?!^${this.negativeSign})`
			: '';
	}

	/**
	 * *Generates a regular expression pattern based on the allowed keys.
	 * *The pattern is created by escaping each key and joining them together.
	 *
	 * @returns The regular expression pattern for the allowed keys.
	 *
	 * @date 13 March 2023
	 * @developer Rahul Kundu
	 */
	private getAllowedKeysRegExp(): string {
		return this.allowKeys.map((key) => `\\${key}`).join('');
	}

	/**
	 * *Checks if the input value is a valid decimal number.
	 *
	 * @param {string} value - The input value to be checked.
	 * @returns True if the value is a valid decimal number, false otherwise.
	 *
	 * @date 13 March 2023
	 * @developer Rahul Kundu
	 */
	private isValidDecimal(value: string): boolean {
		if (this.allowMultipleDecimals) {
			return true; // Allow multiple decimal separators
		} else if (!this.hasDecimalPoint) {
			return value.split(this.decimalSeparator).length <= 2;
		} else {
			// The input element already has a decimal separator
			const selectedText = this.getSelection();
			if (
				selectedText &&
				selectedText.indexOf(this.decimalSeparator) > -1
			) {
				return value.split(this.decimalSeparator).length <= 2;
			} else {
				return value.indexOf(this.decimalSeparator) < 0;
			}
		}
	}

	/**
	 * *Retrieves the selected text within the input element.
	 *
	 * @returns The selected text.
	 *
	 * @date 13 March 2023
	 * @developer Rahul Kundu
	 */
	private getSelection(): string {
		return this.inputElement.value.substring(
			this.inputElement.selectionStart as number,
			this.inputElement.selectionEnd as number
		);
	}

	/**
	 * *Generates the forecasted value after a key press based on the current selection.
	 *
	 * @param {string} key - The pressed key.
	 * @returns The forecasted value after the key press.
	 *
	 * @date 13 March 2023
	 * @developer Rahul Kundu
	 */
	private forecastValue(key: string): string {
		const selectionStart = this.inputElement.selectionStart as number;
		const selectionEnd = this.inputElement.selectionEnd as number;
		const oldValue = this.inputElement.value;
		const selection = oldValue.substring(selectionStart, selectionEnd);
		return selection
			? oldValue.replace(selection, key)
			: oldValue.substring(0, selectionStart) +
					key +
					oldValue.substring(selectionStart);
	}
}
