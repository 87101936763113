import { IPaymentStatus } from '../models';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'paymentStatus'
})
export class PaymentStatusPipe implements PipeTransform {
	/**
	 * *Pipe to format payment status
	 *
	 * @param status
	 * @returns string
	 * @date 06 December 2022
	 * @developer Rahul Kundu
	 */
	public transform(status: IPaymentStatus): string {
		switch (status) {
			case 'voided':
				return 'voided';
			case 'failed':
				return 'failed';
			case 'charged':
				return 'charged';
			case 'refunded':
				return 'refunded';
			case 'credited':
				return 'credited';
			case 'settling':
				return 'settling';
			case 'authorized':
				return 'authorized';
			case 'settlement_pending':
				return 'settlement pending';
			default:
				return '--';
		}
	}
}
