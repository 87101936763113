import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'urlFileExtension'
})
export class UrlFileExtensionPipe implements PipeTransform {
	/**
	 * *Extracts the file extension from a URL.
	 *
	 * @param url The URL from which to extract the file extension.
	 * @returns The file extension extracted from the URL.
	 *
	 * @date 30 June 2022
	 * @developer Rahul Kundu
	 */
	public transform(url: string): string {
		const extensionRegex = /[^./\\]*\.(\w+)(?=\?|$)/;
		const match = url.match(extensionRegex);
		if (match && match.length > 1) {
			return match[1].trim();
		}
		return '';
	}
}
