import { Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { NgProgress, NgProgressRef } from 'ngx-progressbar';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { fadeAnimation, routerAnimation } from '@shared/animations';
import { FileImportService, FileExportService } from '@core/services';
import {
	Event,
	Router,
	NavigationEnd,
	NavigationError,
	NavigationStart,
	NavigationCancel
} from '@angular/router';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.css'],
	animations: [fadeAnimation, routerAnimation]
})
export class AppComponent implements OnInit, OnDestroy {
	constructor(
		private _router: Router,
		private _progress: NgProgress,
		private _toastr: ToastrService,
		private _fileImportService: FileImportService,
		private _fileExportService: FileExportService
	) {
		this.routeHandler();
		this.progressRef = this._progress.ref('progress-bar');
	}

	public routePath!: string;
	public isLoadingFirstTime = true;
	private progressRef!: NgProgressRef;
	private classNameByRoute: string = '';
	private subscriptions: Subscription[] = [];
	private importItemsSubscription = new Subscription();
	private importMenusSubscription = new Subscription();
	private exportMenusSubscription = new Subscription();
	private importRestaurantsSubscription = new Subscription();
	private exportRestaurantsSubscription = new Subscription();
	private importInventoriesSubscription = new Subscription();
	private exportInventoriesSubscription = new Subscription();

	ngOnInit(): void {
		this.isCheckImportStatus();
		this.isCheckExportStatus();
	}

	/**
	 * *Updating progress loader state based on navigation events
	 *
	 * @date 06 December 2022
	 * @developer Rahul Kundu
	 */
	private routeHandler() {
		this.subscriptions.push(
			this._router.events.subscribe({
				next: (event: Event) => {
					if (event instanceof NavigationStart) {
						this.progressRef.start();
						this.routePath = event.url.substring(1);
					}

					if (event instanceof NavigationEnd) {
						this.progressRef.complete();
						this.addClassToBodyByRoutePath();
						if (this.isLoadingFirstTime) {
							setTimeout(() => {
								this.isLoadingFirstTime = false;
							}, 200);
						}
					}

					if (
						event instanceof NavigationError ||
						event instanceof NavigationCancel
					) {
						this.progressRef.complete();
					}
				}
			})
		);
	}

	/**
	 * *Adding class to body tag by route name
	 *
	 * @date 06 December 2022
	 * @developer Rahul Kundu
	 */
	private addClassToBodyByRoutePath(): void {
		if (document.body.classList.contains(this.classNameByRoute)) {
			document.body.classList.remove(this.classNameByRoute);
		}

		const formattedClassName = this.routePath
			.replace(/\/\d+/g, '')
			.replace(/\//g, '-');

		this.classNameByRoute = 'crave-' + formattedClassName;
		document.body.classList.add(this.classNameByRoute);
	}

	/**
	 * *After an file import getting value from subject to track the import status
	 *
	 * @date 23 December 2022
	 * @developer Rahul Kundu
	 */
	private isCheckImportStatus(): void {
		this.subscriptions.push(
			this._fileImportService._importStatusSource$.subscribe({
				next: (value) => {
					if (!!value) {
						switch (value) {
							case 'item':
								this.geItemImportStatus();
								break;
							case 'menu':
								this.getMenusImportStatus();
								break;
							case 'inventory':
								this.getInventoryImportStatus();
								break;
							case 'restaurant':
								this.getRestaurantsImportStatus();
								break;
							default:
								break;
						}
					}
				}
			})
		);
	}

	/**
	 * *After an file export getting value from subject to track the export status
	 *
	 * @date 23 December 2022
	 * @developer Rahul Kundu
	 */
	private isCheckExportStatus(): void {
		this.subscriptions.push(
			this._fileExportService._exportStatusSource$.subscribe({
				next: (value) => {
					if (!!value) {
						switch (value) {
							case 'menu':
								this.getMenusExportStatus();
								break;
							case 'restaurant':
								this.getRestaurantsExportStatus();
								break;
							case 'inventory':
								this.getInventoryExportStatus();
								break;
							default:
								break;
						}
					}
				}
			})
		);
	}

	/**
	 * *Performing HTTP request after every 5 seconds to get the restaurants import status
	 *
	 * @date 23 December 2022
	 * @developer Rahul Kundu
	 */
	private getRestaurantsImportStatus(): void {
		this.importRestaurantsSubscription = this._fileImportService
			.getImportStatus('restaurant')
			.subscribe({
				next: (importApiResult) => {
					if (!!importApiResult) {
						if (
							importApiResult.status == 'error' ||
							importApiResult.status == 'success'
						) {
							if (importApiResult.status == 'error') {
								this._toastr.error(importApiResult.message);
							} else if (importApiResult.status == 'success') {
								//re-rendering list only for resturants list
								this._fileImportService.setToRerenderItems(
									'restaurant'
								);
								this._fileImportService.setOnImportSucess(null);
								this._fileImportService.setToRerenderItems(
									null
								);

								this._toastr.success(
									"Restaurant's imported sucessfully!"
								);
							}

							this.importRestaurantsSubscription.unsubscribe();
						}
					}
				},
				error: (apiError) => {
					this._toastr.error(
						'Something went wrong please try again!'
					);
				}
			});
	}

	/**
	 * *Performing HTTP request after every 5 seconds to get the restaurants export status
	 *
	 * @date 23 December 2022
	 * @developer Rahul Kundu
	 */
	private getRestaurantsExportStatus(): void {
		this.exportRestaurantsSubscription = this._fileExportService
			.getExportStatus('restaurant')
			.subscribe({
				next: (exportApiResult) => {
					if (!!exportApiResult) {
						if (
							exportApiResult.status == 'error' ||
							exportApiResult.status == 'success'
						) {
							if (exportApiResult.status == 'error') {
								this._toastr.error(exportApiResult.message);
							} else if (exportApiResult.status == 'success') {
								this._fileExportService.setOnExportSucess(null);

								this._toastr.success(
									"Restaurant's exported sucessfully!"
								);
							}

							this.exportRestaurantsSubscription.unsubscribe();
						}
					}
				},
				error: (apiError) => {
					this._toastr.error(
						'Something went wrong please try again!'
					);
				}
			});
	}

	/**
	 * *Performing HTTP request after every 5 seconds to get the menus import status
	 *
	 * @date 26 December 2022
	 * @developer Rahul Kundu
	 */
	private getMenusImportStatus(): void {
		this.importMenusSubscription = this._fileImportService
			.getImportStatus('restaurant-menu')
			.subscribe({
				next: (importApiResult) => {
					if (!!importApiResult) {
						if (
							importApiResult.status == 'error' ||
							importApiResult.status == 'success'
						) {
							if (importApiResult.status == 'error') {
								this._toastr.error(importApiResult.message);
							} else if (importApiResult.status == 'success') {
								//re-rendering list only for menus list
								this._fileImportService.setToRerenderItems(
									'menu'
								);
								this._fileImportService.setOnImportSucess(null);
								this._fileImportService.setToRerenderItems(
									null
								);

								this._toastr.success(
									"Menu's imported sucessfully!"
								);
							}

							this.importMenusSubscription.unsubscribe();
						}
					}
				},
				error: (apiError) => {
					this._toastr.error(
						'Something went wrong please try again!'
					);
				}
			});
	}

	/**
	 * *Performing HTTP request after every 5 seconds to get the menus export status
	 *
	 * @date 23 December 2022
	 * @developer Rahul Kundu
	 */
	private getMenusExportStatus(): void {
		this.exportMenusSubscription = this._fileExportService
			.getExportStatus('menu')
			.subscribe({
				next: (exportApiResult) => {
					if (!!exportApiResult) {
						if (
							exportApiResult.status == 'error' ||
							exportApiResult.status == 'success'
						) {
							if (exportApiResult.status == 'error') {
								this._toastr.error(exportApiResult.message);
							} else if (exportApiResult.status == 'success') {
								this._fileExportService.setOnExportSucess(null);

								this._toastr.success(
									"Menu's exported sucessfully!"
								);
							}

							this.exportMenusSubscription.unsubscribe();
						}
					}
				},
				error: (apiError) => {
					this._toastr.error(
						'Something went wrong please try again!'
					);
				}
			});
	}

	/**
	 * *Performing HTTP request after every 5 seconds to get the inventories import status
	 *
	 * @date 10 February 2023
	 * @developer Rahul Kundu
	 */
	private getInventoryImportStatus(): void {
		this.importInventoriesSubscription = this._fileImportService
			.getImportStatus('restaurant-inventory')
			.subscribe({
				next: (importApiResult) => {
					if (!!importApiResult) {
						if (
							importApiResult.status == 'error' ||
							importApiResult.status == 'success'
						) {
							if (importApiResult.status == 'error') {
								this._toastr.error(importApiResult.message);
							} else if (importApiResult.status == 'success') {
								//re-rendering list only for inventory list
								this._fileImportService.setToRerenderItems(
									'inventory'
								);
								this._fileImportService.setOnImportSucess(null);
								this._fileImportService.setToRerenderItems(
									null
								);

								this._toastr.success(
									"Inventory's imported sucessfully!"
								);
							}

							this.importInventoriesSubscription.unsubscribe();
						}
					}
				},
				error: (apiError) => {
					this._toastr.error(
						'Something went wrong please try again!'
					);
				}
			});
	}

	/**
	 * *Performing HTTP request after every 5 seconds to get the inventories export status
	 *
	 * @date 10 February 2023
	 * @developer Rahul Kundu
	 */
	private getInventoryExportStatus(): void {
		this.exportInventoriesSubscription = this._fileExportService
			.getExportStatus('inventory')
			.subscribe({
				next: (exportApiResult) => {
					if (!!exportApiResult) {
						if (
							exportApiResult.status == 'error' ||
							exportApiResult.status == 'success'
						) {
							if (exportApiResult.status == 'error') {
								this._toastr.error(exportApiResult.message);
							} else if (exportApiResult.status == 'success') {
								this._fileExportService.setOnExportSucess(null);

								this._toastr.success(
									"Inventory's exported sucessfully!"
								);
							}

							this.exportInventoriesSubscription.unsubscribe();
						}
					}
				},
				error: (apiError) => {
					this._toastr.error(
						'Something went wrong please try again!'
					);
				}
			});
	}

	/**
	 * *Performing HTTP request after every 5 seconds to get the items import status
	 *
	 * @date 10 May 2024
	 * @developer Rahul Kundu
	 */
	private geItemImportStatus(): void {
		this.importItemsSubscription = this._fileImportService
			.getImportStatus('restaurant-item')
			.subscribe({
				next: (importApiResult) => {
					if (!!importApiResult) {
						if (
							importApiResult.status == 'error' ||
							importApiResult.status == 'success'
						) {
							if (importApiResult.status == 'error') {
								this._toastr.error(importApiResult.message);
							} else if (importApiResult.status == 'success') {
								//re-rendering list only for inventory list
								this._fileImportService.setToRerenderItems(
									'item'
								);
								this._fileImportService.setOnImportSucess(null);
								this._fileImportService.setToRerenderItems(
									null
								);

								this._toastr.success(
									'Menu Items imported sucessfully!'
								);
							}

							this.importItemsSubscription.unsubscribe();
						}
					}
				},
				error: (apiError) => {
					this._toastr.error(
						'Something went wrong please try again!'
					);
				}
			});
	}

	ngOnDestroy(): void {
		this.importMenusSubscription.unsubscribe();
		this.exportMenusSubscription.unsubscribe();

		this.importItemsSubscription.unsubscribe();
		this.importItemsSubscription.unsubscribe();

		this.importRestaurantsSubscription.unsubscribe();
		this.exportRestaurantsSubscription.unsubscribe();

		this.importInventoriesSubscription.unsubscribe();
		this.exportInventoriesSubscription.unsubscribe();

		this.subscriptions.forEach((subscription) =>
			subscription.unsubscribe()
		);
	}
}
