import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'aggregate'
})
export class AggregatePipe implements PipeTransform {
	/**
	 * *Pipe to aggregate the values of a specified key from an array of objects,
	 * *Optionally format the total with a prefix and specified number of decimal places.
	 *
	 * @param value - The array of objects to aggregate values from.
	 * @param args[0] - The key in each object to aggregate values from.
	 * @param args[1] - (Optional) The prefix to be added to the total.
	 * @param args[2] - (Optional) The number of decimal places to include in the total.
	 * @returns A string representing the aggregated total with prefix and decimal places if specified.
	 *
	 * @date 19 February 2024
	 * @developer Rahul Kundu
	 */
	public transform(value: unknown[], ...args: unknown[]): string {
		if (!Array.isArray(value) || value.length === 0) {
			return '--';
		}

		if (!args.length || typeof args[0] !== 'string') {
			return '--';
		}

		let prefix: string = '';
		let key: string = args[0];
		let decimalPlaces: number = 0;

		if (args.length > 1 && typeof args[1] === 'string') {
			prefix = args[1];
		}

		if (args.length > 2 && typeof args[2] === 'number') {
			decimalPlaces = Math.round(args[2]);
		}

		const total = value.reduce((sum: number, item: any) => {
			const itemValue = this.getItemValue(item, key);

			if (!isNaN(itemValue)) {
				return sum + itemValue;
			}

			return sum;
		}, 0);

		const formattedTotal = prefix + total.toFixed(decimalPlaces);

		return formattedTotal;
	}

	/**
	 * *Gets the value of a specified key from an object, and attempts to parse it as a number.
	 * *Returns NaN if the value cannot be parsed as a number.
	 *
	 * @param item - The object to extract the value from.
	 * @param key - The key to extract the value for.
	 * @returns The value of the key as a number, or NaN if it cannot be parsed as a number.
	 *
	 * @date 19 February 2024
	 * @developer Rahul Kundu
	 */
	private getItemValue(item: any, key: string): number {
		if (typeof item === 'object' && key in item) {
			const keyValue = (item as Record<string, unknown>)[key];

			if (typeof keyValue === 'string') {
				return parseFloat(keyValue);
			}

			return keyValue as number;
		}

		return NaN;
	}
}
