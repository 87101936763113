import { fromEvent } from 'rxjs';
import { take } from 'rxjs/operators';
import {
	OnInit,
	Output,
	Directive,
	ElementRef,
	EventEmitter,
	HostListener
} from '@angular/core';

@Directive({
	selector: '[clickOutside]'
})
export class ClickOutsideDirective implements OnInit {
	/**
	 * *Directive for handling click events outside an element
	 * *Useful for things like reacting to clicking outside of a dropdown menu or modal dialog.
	 *
	 * @date 05 December 2022
	 * @developer Rahul Kundu
	 */

	@Output()
	public clickOutside = new EventEmitter();
	private captured = false;

	constructor(private _elementRef: ElementRef) {}

	@HostListener('document:click', ['$event.target'])
	onClick(target: HTMLElement) {
		if (!this.captured) {
			return;
		}

		if (!this._elementRef.nativeElement.contains(target)) {
			this.clickOutside.emit();
		}
	}

	ngOnInit() {
		fromEvent(document, 'click', { capture: true })
			.pipe(take(1))
			.subscribe(() => (this.captured = true));
	}
}
