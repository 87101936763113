import moment from 'moment';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'momentTimeDiff'
})
export class MomentTimeDiffPipe implements PipeTransform {
	/**
	 * *Finds time difference between two dates in hour minutes and seconds
	 *
	 * @param endDate
	 * @param startDate
	 * @returns string
	 */
	transform(
		endDate: Date | string | null | undefined,
		startDate: Date | string | null | undefined
	): string | null {
		if (!!endDate && !!startDate) {
			const endMoment = moment(endDate);
			const startMoment = moment(startDate);

			const timeDiffDuartion = moment.duration(
				endMoment.diff(startMoment)
			);

			const [hour, minute, second] = moment
				.utc(timeDiffDuartion.asMilliseconds())
				.format('H:mm:ss')
				.split(':');

			return `${hour}h ${minute}m ${second}s`;
		}
		return null;
	}
}
