<div layoutBreakpoint class="crave-admin-layout-outer">
	<crave-admin-header></crave-admin-header>
	<crave-admin-sidebar></crave-admin-sidebar>
	<div class="crave-admin-layout-inner">
		<ng-scrollbar
			[visibility]="'hover'"
			[appearance]="'compact'"
			[autoHeightDisabled]="true"
			[viewClass]="'custom-scrollbar'"
			[pointerEventsMethod]="'scrollbar'"
			[trackClass]="'custom-scrollbar-track'"
			[thumbClass]="'custom-scrollbar-thumb'"
		>
			<div cdkScrollable scrollViewport>
				<div class="crave-admin-layout-content">
					<router-outlet></router-outlet>
				</div>
			</div>
		</ng-scrollbar>
	</div>
</div>
