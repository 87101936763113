export const appSettings = {
	ajaxTimeout: 300000,
	preloadDelayMS: 500,
	socketRetryDelayMS: 1000,
	appTitle: 'Cräveble Admin',
	appTitleSeperator: ' | ',
	credentialsKey: 'crave-admin-user',
	navCredentialsKey: 'crave-admin-nav',
	privilegedEmails: ['veer@craveble.com'],
	rememberMeKey: 'crave-admin-user-remember',
	socketRetryAttempts: Number.POSITIVE_INFINITY,
	checkoutCredentialsKey: 'crave-admin-checkout',
	editOrderCredentialsKey: 'crave-admin-edit-order',
	cryptoSecret: 'zsgj8o4ugor4c47red9kcwd0tikq237efcqumg4b4totipxhab',
	fedexTrackingUrl: 'https://www.fedex.com/fedextrack/?trknbr=TRACKINGID',
	upsTrackingUrl:
		'https://www.ups.com/track?loc=en_US&trackNums=TRACKINGID&requester=ST/trackdetails'
};
