import { Injectable } from '@angular/core';
import { appSettings } from '@app/configs';
import { environment } from '@env/environment';
import { ISocketResult } from '@shared/models';
import { LoggerService } from './logger.service';
import { NextObserver, Observable, map, retry, merge } from 'rxjs';
import {
	webSocket,
	WebSocketSubject,
	WebSocketSubjectConfig
} from 'rxjs/webSocket';

@Injectable()
export class SocketService {
	constructor(private _logger: LoggerService) {}

	private isAdminSocketConnected = false;
	private isFrontendSocketConnected = false;
	private retryDelay = appSettings.socketRetryDelayMS;
	private WS_ADMIN_ENDPOINT = environment.adminSocketHost;
	private maxRetryAttempts = appSettings.socketRetryAttempts;
	private WS_FRONTEND_ENDPOINT = environment.frontendSocketHost;
	private _adminSocketSubject!: WebSocketSubject<ISocketResult | null>;
	private _frontendSocketSubject!: WebSocketSubject<ISocketResult | null>;

	/**
	 * *Connects to both frontend and admin WebSocket servers.
	 *
	 * @date 01 February 2024
	 * @developer Rahul Kundu
	 */
	public connect(): void {
		// Connect to the frontend socket if not connected or closed
		if (
			!this._frontendSocketSubject ||
			this._frontendSocketSubject.closed
		) {
			this._frontendSocketSubject = this.createSocketInstance(
				this.WS_FRONTEND_ENDPOINT
			);
		}

		// Connect to the admin socket if not connected or closed
		if (!this._adminSocketSubject || this._adminSocketSubject.closed) {
			this._adminSocketSubject = this.createSocketInstance(
				this.WS_ADMIN_ENDPOINT
			);
		}
	}

	/**
	 * *Closes both frontend and admin WebSocket connections.
	 *
	 * @date 01 February 2024
	 * @developer Rahul Kundu
	 */
	public close(): void {
		// Close the admin socket
		this._adminSocketSubject.complete();
		// Close the frontend socket
		this._frontendSocketSubject.complete();
	}

	/**
	 * *Set order details to the frontend WebSocket subject.
	 *
	 * @param order The order details to send to the frontend WebSocket.
	 *
	 * @date 01 February 2024
	 * @developer Rahul Kundu
	 */
	public setFrontendOrderDetailsToSocket(order: ISocketResult | null): void {
		this._frontendSocketSubject.next(order);
	}

	/**
	 * *Set order details to the admin WebSocket subject.
	 *
	 * @param order The order details to send to the admin WebSocket.
	 *
	 * @date 01 February 2024
	 * @developer Rahul Kundu
	 */
	public setAdminOrderDetailsToSocket(order: ISocketResult | null): void {
		this._adminSocketSubject.next(order);
	}

	/**
	 * *Gets the order details from both frontend and admin WebSockets.
	 *
	 * @returns An Observable that emits ISocketResult or null values.
	 *
	 * @date 01 February 2024
	 * @developer Rahul Kundu
	 */
	public getOrderDetailsFromSocket(): Observable<ISocketResult | null> {
		const frontendObservable = this.createSocketObservable(
			this._frontendSocketSubject
		);
		const adminObservable = this.createSocketObservable(
			this._adminSocketSubject
		);

		// Combine the two observables into one
		return merge(frontendObservable, adminObservable);
	}

	/**
	 * *Creates a new WebSocket instance with auto-reconnect.
	 *
	 * @param endpoint The WebSocket server endpoint.
	 * @returns WebSocketSubject<ISocketResult | null> The WebSocket subject.
	 *
	 * @date 19 July 2023
	 * @developer Rahul Kundu
	 */
	private createSocketInstance(
		endpoint: string
	): WebSocketSubject<ISocketResult | null> {
		const socketConfig: WebSocketSubjectConfig<ISocketResult | null> = {
			url: endpoint,
			openObserver: this.createOpenObserver(endpoint),
			closeObserver: this.createCloseObserver(endpoint),
			deserializer: (event: MessageEvent) => {
				try {
					const orderDetails = JSON.parse(
						event.data
					) as ISocketResult;
					return orderDetails;
				} catch (error) {
					console.error('Error during deserialization:', error);
					return null;
				}
			}
		};

		return webSocket(socketConfig).pipe(
			retry({ count: this.maxRetryAttempts, delay: this.retryDelay })
		) as WebSocketSubject<ISocketResult | null>;
	}

	/**
	 * *Creates an observable for a WebSocket subject.
	 *
	 * @param socketSubject The WebSocket subject to create an observable for.
	 * @returns An Observable that emits ISocketResult or null values.
	 *
	 * @date 01 February 2024
	 * @developer Rahul Kundu
	 */
	private createSocketObservable(
		socketSubject: WebSocketSubject<ISocketResult | null>
	): Observable<ISocketResult | null> {
		// Create a variable to check if the socket is the frontend socket
		const isFrontend = socketSubject === this._frontendSocketSubject;

		// Pipe the observable and map the data to a new format
		return socketSubject.asObservable().pipe(
			// Check if data is null, return null
			map((data) => {
				if (!data) {
					return null;
				}

				// Destructure data to extract order and event
				const { order_details: order, event } = data;

				// If order is null, return the original data
				if (!order) {
					return data;
				}

				// Determine payment status based on the event
				const paymentStatus =
					data.event === 'completeOrder'
						? 'charged'
						: order.payment_status;

				// Determine the source of the event (frontend or admin)
				const source = isFrontend ? 'frontend' : 'admin';

				// Return the transformed data with source information
				return {
					event,
					source,
					order_details: {
						...order,
						payment_status: paymentStatus
					}
				};
			})
		);
	}

	/**
	 * *Creates the open observer for the WebSocket connection.
	 *
	 * @param endpoint The WebSocket server endpoint.
	 * @returns NextObserver<Event | undefined> The open observer object.
	 *
	 * @date 01 February 2024
	 * @developer Rahul Kundu
	 */
	private createOpenObserver(
		endpoint: string
	): NextObserver<Event | undefined> {
		return {
			next: (event) => {
				switch (endpoint) {
					case this.WS_ADMIN_ENDPOINT:
						if (!this.isAdminSocketConnected)
							this.isAdminSocketConnected = true;
						break;
					case this.WS_FRONTEND_ENDPOINT:
						if (!this.isFrontendSocketConnected)
							this.isFrontendSocketConnected = true;
						break;
					default:
						this._logger.error(
							`Unexpected WebSocket server at ${endpoint}. Unable to determine the source.`
						);
						return;
				}

				// Check if both sockets are connected
				if (
					this.isAdminSocketConnected &&
					this.isFrontendSocketConnected
				) {
					this._logger.info(
						'Frontend and Admin sockets are connected to the WebSocket server!'
					);
				}
			}
		};
	}

	/**
	 * *Creates the close observer for the WebSocket connection.
	 *
	 * @param endpoint The WebSocket server endpoint.
	 * @returns NextObserver<Event | undefined> The close observer object.
	 *
	 * @date 01 February 2024
	 * @developer Rahul Kundu
	 */
	private createCloseObserver(
		endpoint: string
	): NextObserver<Event | undefined> {
		return {
			next: (event) => {
				switch (endpoint) {
					case this.WS_FRONTEND_ENDPOINT:
						if (this.isFrontendSocketConnected)
							this.isFrontendSocketConnected = false;
						break;
					case this.WS_ADMIN_ENDPOINT:
						if (this.isAdminSocketConnected)
							this.isAdminSocketConnected = false;
						break;
					default:
						this._logger.warn(
							`Unexpected WebSocket server at ${endpoint}.`
						);
						return;
				}

				// Check if both frontend and admin sockets are disconnected
				if (
					!this.isAdminSocketConnected &&
					!this.isFrontendSocketConnected
				) {
					this._logger.info(
						`Frontend and Admin sockets are disconnected from the WebSocket server!`
					);
				}
			}
		};
	}
}
