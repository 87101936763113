import { NgModule } from '@angular/core';
import { authGuard } from '@core/guards';
import { RouterModule, Routes } from '@angular/router';
import { PreloadModulesStrategy } from '@core/strategies';
import {
	CraveAuthLayoutComponent,
	CraveAdminLayoutComponent
} from '@core/layouts';

const appRoutes: Routes = [
	{
		path: '',
		component: CraveAuthLayoutComponent,
		loadChildren: () =>
			import('./pages/crave-auth/crave-auth.module').then(
				(m) => m.CraveAuthModule
			)
	},
	{
		path: '',
		canActivate: [authGuard],
		component: CraveAdminLayoutComponent,
		children: [
			{
				path: '',
				data: { preload: true },
				loadChildren: () =>
					import(
						'./pages/crave-dashboard/crave-dashboard.module'
					).then((m) => m.CraveDashboardModule)
			},
			{
				path: '',
				data: { preload: true },
				loadChildren: () =>
					import(
						'./pages/crave-live-orders/crave-live-orders.module'
					).then((m) => m.CraveLiveOrdersModule)
			},
			{
				path: '',
				data: { preload: true },
				loadChildren: () =>
					import('./pages/crave-orders/crave-orders.module').then(
						(m) => m.CraveOrdersModule
					)
			},
			{
				path: '',
				data: { preload: true },
				loadChildren: () =>
					import(
						'./pages/crave-restaurants/crave-restaurants.module'
					).then((m) => m.CraveRestaurantsModule)
			},
			{
				path: '',
				data: { preload: true },
				loadChildren: () =>
					import('./pages/crave-menus/crave-menus.module').then(
						(m) => m.CraveMenusModule
					)
			},
			{
				path: '',
				data: { preload: true },
				loadChildren: () =>
					import(
						'./pages/crave-category-tags/crave-category-tags.module'
					).then((m) => m.CraveCategoryTagsModule)
			},
			{
				path: '',
				data: { preload: true },
				loadChildren: () =>
					import('./pages/crave-zones/crave-zones.module').then(
						(m) => m.CraveZonesModule
					)
			},
			{
				path: '',
				data: { preload: true },
				loadChildren: () =>
					import(
						'./pages/crave-eating-preferences/crave-eating-preferences.module'
					).then((m) => m.CraveEatingPreferencesModule)
			},
			{
				path: '',
				data: { preload: true },
				loadChildren: () =>
					import(
						'./pages/crave-diet-types/crave-diet-types.module'
					).then((m) => m.CraveDietTypesModule)
			},
			{
				path: '',
				data: { preload: true },
				loadChildren: () =>
					import(
						'./pages/crave-discounts/crave-discounts.module'
					).then((m) => m.CraveDiscountsModule)
			},
			{
				path: '',
				data: { preload: true },
				loadChildren: () =>
					import('./pages/crave-faqs/crave-faqs.module').then(
						(m) => m.CraveFaqsModule
					)
			},
			{
				path: '',
				data: { preload: true },
				loadChildren: () =>
					import(
						'./pages/crave-employees/crave-employees.module'
					).then((m) => m.CraveEmployeesModule)
			},
			{
				path: '',
				data: { preload: true },
				loadChildren: () =>
					import('./pages/crave-promos/crave-promos.module').then(
						(m) => m.CravePromosModule
					)
			},
			{
				path: '',
				data: { preload: true },
				loadChildren: () =>
					import(
						'./pages/crave-warehouses/crave-warehouses.module'
					).then((m) => m.CraveWarehousesModule)
			},
			{
				path: '',
				data: { preload: true },
				loadChildren: () =>
					import('./pages/crave-hubs/crave-hubs.module').then(
						(m) => m.CraveHubsModule
					)
			},
			{
				path: '',
				data: { preload: true },
				loadChildren: () =>
					import(
						'./pages/crave-global-settings/crave-global-settings.module'
					).then((m) => m.CraveGlobalSettingsModule)
			},
			{
				path: '',
				data: { preload: true },
				loadChildren: () =>
					import(
						'./pages/crave-fcm-files/crave-fcm-files.module'
					).then((m) => m.CraveFcmFilesModule)
			},
			{
				path: '',
				data: { preload: true },
				loadChildren: () =>
					import(
						'./pages/crave-support-login/crave-support-login.module'
					).then((m) => m.CraveSupportLoginModule)
			},
			{
				path: '',
				data: { preload: true },
				loadChildren: () =>
					import(
						'./pages/crave-merchant-users/crave-merchant-users.module'
					).then((m) => m.CraveMerchantUsersModule)
			},
			{
				path: '',
				data: { preload: true },
				loadChildren: () =>
					import(
						'./pages/crave-inventory/crave-inventory.module'
					).then((m) => m.CraveInventoryModule)
			},
			{
				path: '',
				data: { preload: true },
				loadChildren: () =>
					import(
						'./pages/crave-customers/crave-customers.module'
					).then((m) => m.CraveCustomersModule)
			},
			{
				path: '',
				data: { preload: true },
				loadChildren: () =>
					import('./pages/crave-batch/crave-batch.module').then(
						(m) => m.CraveBatchModule
					)
			},
			{
				path: '',
				data: { preload: true },
				loadChildren: () =>
					import(
						'./pages/crave-profile-settings/crave-profile-settings.module'
					).then((m) => m.CraveProfileSettingsModule)
			},
			{
				path: '',
				data: { preload: true },
				loadChildren: () =>
					import('./pages/crave-roles/crave-roles.module').then(
						(m) => m.CraveRolesModule
					)
			},
			{
				path: '',
				data: { preload: true },
				loadChildren: () =>
					import('./pages/crave-site-map/crave-site-map.module').then(
						(m) => m.CraveSiteMapModule
					)
			},
			{
				path: '',
				data: { preload: true },
				loadChildren: () =>
					import(
						'./pages/crave-menu-view/crave-menu-view.module'
					).then((m) => m.CraveMenuViewModule)
			},
			{
				path: '',
				data: { preload: true },
				loadChildren: () =>
					import(
						'./pages/crave-feedbacks/crave-feedbacks.module'
					).then((m) => m.CraveFeedbacksModule)
			},
			{
				path: '',
				data: { preload: true },
				loadChildren: () =>
					import(
						'./pages/crave-testimonials/crave-testimonials.module'
					).then((m) => m.CraveTestimonialsModule)
			},
			{
				path: '',
				data: { preload: true },
				loadChildren: () =>
					import(
						'./pages/crave-weekly-cravings/crave-weekly-cravings.module'
					).then((m) => m.CraveWeeklyCravingsModule)
			},
			{
				path: '',
				data: { preload: true },
				loadChildren: () =>
					import(
						'./pages/crave-home-settings/crave-home-settings.module'
					).then((m) => m.CraveHomeSettingsModule)
			},
			{
				path: '',
				data: { preload: true },
				loadChildren: () =>
					import(
						'./pages/crave-meta-data/crave-meta-data.module'
					).then((m) => m.CraveMetaDataModule)
			},
			{
				path: '',
				data: { preload: true },
				loadChildren: () =>
					import('./pages/crave-reports/crave-reports.module').then(
						(m) => m.CraveReportsModule
					)
			},
			{
				path: '',
				data: { preload: true },
				loadChildren: () =>
					import(
						'./pages/crave-subscriptions/crave-subscriptions.module'
					).then((m) => m.CraveSubscriptionsModule)
			}
		]
	},
	{
		path: '**',
		data: { preload: true },
		loadChildren: () =>
			import(
				'./pages/crave-page-not-found/crave-page-not-found.module'
			).then((m) => m.CravePageNotFoundModule)
	}
];

@NgModule({
	imports: [
		RouterModule.forRoot(appRoutes, {
			scrollPositionRestoration: 'top',
			paramsInheritanceStrategy: 'always',
			preloadingStrategy: PreloadModulesStrategy
		})
	],
	exports: [RouterModule]
})
export class AppRoutingModule {}
