import { IOrderStatus } from '../models';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'orderStatus'
})
export class OrderStatusPipe implements PipeTransform {
	/**
	 * *Pipe to format order status
	 *
	 * @param status
	 * @returns string
	 * @date 06 December 2022
	 * @developer Rahul Kundu
	 */
	public transform(status: IOrderStatus): string {
		switch (status) {
			case 'order_complete':
				return 'completed';
			case 'order_processing':
				return 'scheduled';
			case 'refunded_credit':
				return 'refund credited';
			case 'order_canceled':
				return 'cancelled';
			case 'order_returned':
				return 'returned';
			case 'order_in_transit':
				return 'in transit';
			case 'label_generated':
				return 'labeled';
			default:
				return '--';
		}
	}
}
