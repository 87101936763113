import moment from 'moment-timezone';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'momentDate'
})
export class MomentDatePipe implements PipeTransform {
	/**
	 * *Formats a date using moment js
	 *
	 * @param value
	 * @param format
	 * @param timezone
	 * @returns string
	 * @date 08 December 2022
	 * @developer Rahul Kundu
	 */
	public transform(
		value: string | Date | null | undefined,
		format: string = 'mediumDate',
		timezone?: string
	): string | null {
		return !!value
			? !!timezone
				? moment.utc(value).tz(timezone).format(format)
				: moment.utc(value).format(format)
			: null;
	}
}
