import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
	selector: 'img[imageFallback]'
})
export class ImageFallbackDirective {
	constructor(private _eRef: ElementRef) {}

	@Input() fallbackImage!: string;

	/**
	 * *Image fallback directive to show default image
	 *
	 * @date 23 December 2022
	 * @developer Rahul Kundu
	 */
	@HostListener('error')
	onError() {
		const element: HTMLImageElement = <HTMLImageElement>(
			this._eRef.nativeElement
		);
		element.src = this.fallbackImage || './assets/images/no-image.svg';
	}
}
