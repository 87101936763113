import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'capitalize'
})
export class CapitalizePipe implements PipeTransform {
	/**
	 * *Transforms the input string by capitalizing the first letter of the sentence or each word.
	 *
	 * @param value - The input string to be transformed.
	 * @param allWords - Optional parameter to determine if all words should have their first letter capitalized (default: false).
	 * @returns The transformed string with the first letter of the sentence or each word capitalized.
	 *
	 * @date 29 June 2022
	 * @developer Rahul Kundu
	 */
	public transform(value: string, allWords: boolean = false): string {
		if (!value) return value;

		if (allWords) {
			return value
				.toLowerCase()
				.split(' ')
				.map((word) => this.capitalizeFirstLetter(word))
				.join(' ');
		} else {
			return this.capitalizeFirstLetter(value);
		}
	}

	/**
	 * *Capitalizes the first letter of a given string and converts the rest to lowercase.
	 *
	 * @param str - The input string to be transformed.
	 * @returns The transformed string with the first letter capitalized and the rest in lowercase.
	 *
	 * @date 29 June 2022
	 * @developer Rahul Kundu
	 */
	private capitalizeFirstLetter(str: string): string {
		return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
	}
}
