<div class="crave-navigation-inner">
	<div class="crave-navigation-top">
		<a>
			<img
				alt=""
				width="130"
				height="30"
				src="./assets/images/logo.svg"
			/>
		</a>
	</div>
	<div class="crave-navigation-middle">
		<ng-scrollbar
			[visibility]="'hover'"
			[appearance]="'compact'"
			[autoHeightDisabled]="false"
			[viewClass]="'custom-scrollbar'"
			[pointerEventsMethod]="'scrollbar'"
			[trackClass]="'custom-scrollbar-track'"
			[thumbClass]="'custom-scrollbar-thumb'"
		>
			<div class="crave-navigation">
				<ul>
					<ng-container *ngFor="let menu of menus">
						<ng-container
							*ngIf="!menu.items; else menuWithSubItems"
						>
							<li
								[routerLinkActive]="'crave-nav-active'"
								[routerLinkActiveOptions]="
									menu.routerLinkActiveOptions || {
										exact: false
									}
								"
							>
								<a
									[routerLink]="menu.routerLink"
									[queryParams]="menu.queryParams"
								>
									<span [class]="menu.icon"></span>
									<p>{{ menu.label }}</p>
								</a>
							</li>
						</ng-container>
						<ng-template #menuWithSubItems>
							<li
								[ngClass]="{
									'crave-nav-open': menu.expanded,
									'crave-nav-highlight':
										menu.isActiveRoute && !menu.expanded
								}"
							>
								<a (click)="onToggleMenu($event, menu)">
									<span [class]="menu.icon"></span>
									<p>{{ menu.label }}</p>
									<i class="material-symbols-rounded">
										chevron_right
									</i>
								</a>
								<ng-container *ngIf="menu.expanded">
									<div
										[@toggleHeight]
										[@.disabled]="isLoadingFirstTime"
										class="crave-navigation-sub-menus-outer"
									>
										<div
											class="crave-navigation-sub-menus-inner"
										>
											<ul>
												<ng-container
													*ngFor="
														let subMenu of menu.items
													"
												>
													<li
														[routerLinkActive]="
															'crave-sub-nav-active'
														"
														[class.crave-sub-nav-active]="
															subMenu.isSubmenuActive
														"
													>
														<a
															[routerLink]="
																subMenu.routerLink
															"
															(click)="
																onClickSubMenu(
																	$event,
																	subMenu
																)
															"
														>
															{{ subMenu.label }}
														</a>
													</li>
												</ng-container>
											</ul>
										</div>
									</div>
								</ng-container>
							</li>
						</ng-template>
					</ng-container>
				</ul>
			</div>
		</ng-scrollbar>
	</div>
	<div class="crave-navigation-bottom">
		<div class="crave-navigation-bottom-left">
			<img
				alt=""
				width="20"
				height="20"
				src="./assets/images/logo-copyright.svg"
			/>
		</div>
		<div class="crave-navigation-bottom-right">
			<p>
				{{ currentYear }} | Craveble
				<span>All rights reserved.</span>
			</p>
		</div>
	</div>
</div>
