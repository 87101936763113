import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { IResponse } from '@shared/models';
import { catchError } from 'rxjs/operators';
import { throwError, Observable } from 'rxjs';
import { environment } from '@env/environment';
import { LoggerService } from '@core/services';
import { StatusCodes } from 'http-status-codes';
import { AuthenticationService } from '@core/authentication';
import {
	HttpEvent,
	HttpRequest,
	HttpHandler,
	HttpInterceptor,
	HttpErrorResponse
} from '@angular/common/http';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
	constructor(
		private _router: Router,
		private _logger: LoggerService,
		private _authService: AuthenticationService
	) {}

	/**
	 * *Interceptor for handling error
	 *
	 * @date 05 December 2022
	 * @developer Rahul Kundu
	 */
	public intercept(
		request: HttpRequest<any>,
		next: HttpHandler
	): Observable<HttpEvent<IResponse>> {
		return next
			.handle(request)
			.pipe(
				catchError((error) => this.errorHandler(error, request, next))
			);
	}

	/**
	 * *Handling error based on response codes
	 *
	 * @param error
	 * @date 05 December 2022
	 * @developer Rahul Kundu
	 */
	private errorHandler(
		error: HttpErrorResponse,
		request: HttpRequest<any>,
		next: HttpHandler
	): Observable<HttpEvent<IResponse>> {
		if (error.error instanceof ErrorEvent) {
			if (!environment.production) {
				/**
				 * !A client-side or network error occurred. Handle it accordingly.
				 * !in development mode printing errors in console
				 */
				this._logger.log('Request error ' + error);
			}
		} else {
			const httpErrorCode: number = error['status'];
			switch (httpErrorCode) {
				case StatusCodes.BAD_REQUEST:
					this._router.navigate(['/page-not-found']);
					break;
				case StatusCodes.UNAUTHORIZED:
					this._authService.clearUserInfo();
					this._router.navigate(['/sign-in']);
					break;
				case StatusCodes.NOT_FOUND:
					this._router.navigate(['/page-not-found']);
					break;
				default:
					this._logger.log('Sorry! something went wrong.', 'Error!');
			}
		}

		return throwError(() => error);
	}
}
