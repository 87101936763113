import { Directive, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({
	selector: '[middleClick]'
})
export class MiddleClickDirective {
	constructor() {}

	@Output() onMiddleClick: EventEmitter<MouseEvent> =
		new EventEmitter<MouseEvent>();

	/**
	 * *Directive to emit event on middle clicn and ctrl + click
	 *
	 * @param event
	 * @date 05 January 2023
	 * @developer Rahul Kundu
	 */
	@HostListener('mouseup', ['$event'])
	onMiddleMouseClick(event: MouseEvent) {
		if (event.button === 1 || (event.ctrlKey && event.button === 0)) {
			this.onMiddleClick.emit(event);
		}
	}

	/**
	 * *Preventing the default scroll by movement
	 *
	 * @param ev
	 * @date 05 January 2023
	 * @developer Rahul Kundu
	 */
	@HostListener('mousedown', ['$event'])
	preventMiddleClickScroll(ev: MouseEvent) {
		if (ev.button === 1) {
			ev.preventDefault();
		}
	}
}
