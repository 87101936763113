import { Subscription } from 'rxjs';
import { toggleHeight } from '@shared/animations';
import { OnInit, Component, OnDestroy } from '@angular/core';
import { CommonService, WindowRefService } from '@core/services';
import { Router, Event, NavigationStart } from '@angular/router';

@Component({
	selector: 'crave-admin-sidebar',
	templateUrl: './crave-admin-sidebar.component.html',
	styleUrls: ['./crave-admin-sidebar.component.css'],
	animations: [toggleHeight]
})
export class CraveAdminSidebarComponent implements OnInit, OnDestroy {
	constructor(
		private _router: Router,
		private _windowRef: WindowRefService,
		private _commonService: CommonService
	) {
		this.onRouteNavigationEnd();
		this.window = this._windowRef.nativeWindow;
	}

	public isSidebarVisible!: boolean;
	public window!: { [key: string]: any };
	private subscriptions: Subscription[] = [];

	ngOnInit(): void {
		this.getSidebarVisibilityState();
	}

	/**
	 * *Updating sidebar visibility state
	 *
	 * @date 14 December 2022
	 * @developer Rahul Kundu
	 */
	private onRouteNavigationEnd() {
		this.subscriptions.push(
			this._router.events.subscribe({
				next: (event: Event) => {
					if (event instanceof NavigationStart) {
						this.onCloseSidebar();
					}
				}
			})
		);
	}

	/**
	 * *Closes sidebar if it is open
	 *
	 * @param event
	 * @date 04 July 2023
	 * @developer Rahul Kundu
	 */
	public onCloseSidebar(event?: MouseEvent): void {
		if (event) event.preventDefault();

		if (this.isSidebarVisible) {
			this._commonService.setSidebarVisibilityState(false);
		}
	}

	/**
	 * *Gets sidebar visiblity state from the subject value
	 *
	 * @date 04 July 2023
	 * @developer Rahul Kundu
	 */
	private getSidebarVisibilityState(): void {
		this.subscriptions.push(
			this._commonService.sidebarVisibilityState$.subscribe({
				next: (value) => {
					this.isSidebarVisible = value;
				}
			})
		);
	}

	ngOnDestroy(): void {
		this.subscriptions.forEach((subscription) =>
			subscription.unsubscribe()
		);
	}
}
