import { Injectable } from '@angular/core';
import { Observable, of, ReplaySubject } from 'rxjs';

@Injectable()
export class LibraryLoaderService {
	loadedLibraries: { [url: string]: ReplaySubject<void> } = {};

	/**
	 * *Loads external scripts
	 *
	 * @param url
	 * @param type
	 * @param options
	 * @returns Observable<void>
	 * @date 05 December 2022
	 * @developer Rahul Kundu
	 */
	public loadLibary(
		url: string,
		type: 'script' | 'style',
		options?: {
			async?: boolean;
		}
	): Observable<void> {
		if (!url) return of();

		const key = url.slice(url.lastIndexOf('/') + 1);

		if (this.loadedLibraries[key]) {
			return this.loadedLibraries[key].asObservable();
		}

		this.loadedLibraries[key] = new ReplaySubject();

		const library = document.createElement(
			type === 'script' ? 'script' : 'link'
		);

		if (type === 'script') {
			library.type = 'text/javascript';
			(library as HTMLScriptElement).src = url;
			if (!!options && !!options.async)
				(library as HTMLScriptElement).async = true;
		} else {
			library.type = 'text/css';
			(library as HTMLLinkElement).rel = 'stylesheet';
			(library as HTMLLinkElement).href = url;
		}

		library.onload = () => {
			this.loadedLibraries[key].next();
			this.loadedLibraries[key].complete();
		};

		document.body.appendChild(library);

		return this.loadedLibraries[key].asObservable();
	}
}
