import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'shrinkNumber'
})
export class ShrinkNumberPipe implements PipeTransform {
	/**
	 * *Pipe to transform a number into a shorter representation with suffixes like K, M, B, and T,
	 * *and with an optional prefix and specified number of decimal places.
	 *
	 * @param value - The number or string representation of a number to be transformed.
	 * @param args[0] - (Optional) The prefix to be added to the transformed number (e.g., currency symbol).
	 * @param args[1] - (Optional) The number of decimal places to include in the transformed number.
	 * @returns A string representing the shorter form of the number with appropriate suffix, prefix, and decimal places.
	 *
	 * @date 19 February 2024
	 * @developer Rahul Kundu
	 */
	public transform(value: number | string, ...args: unknown[]): string {
		let prefix: string = '';
		let decimalPlaces: number = 0; // Default to 0 decimal places

		// Check if prefix is provided
		if (args.length > 0 && typeof args[0] === 'string') {
			prefix = args[0];
		}

		// Check if decimal places are provided
		if (args.length > 1 && typeof args[1] === 'number') {
			decimalPlaces = Math.round(args[1]);
		}

		// Parse the input value to a number
		const num = typeof value === 'number' ? value : parseFloat(value);
		if (isNaN(num) || num === 0) return '';

		// Define the suffixes for shorter representations
		const degrees = [
			{ sym: 'T', val: Math.pow(10, 12) },
			{ sym: 'B', val: Math.pow(10, 9) },
			{ sym: 'M', val: Math.pow(10, 6) },
			{ sym: 'k', val: 1000 }
		];

		// Define the rounder based on the number of decimal places
		const rounder = Math.pow(10, decimalPlaces);
		let abs = Math.abs(num);
		let symbol = '';

		// Find the appropriate suffix for the number
		for (const degree of degrees) {
			const sample = abs / degree.val;
			const rounded = Math.round(sample * rounder) / rounder;
			if (rounded >= 1) {
				abs = rounded;
				symbol = degree.sym;
				break;
			}
		}

		// Format the number with the specified decimal places
		let formattedNumber = abs.toFixed(decimalPlaces);

		// Append zeroes to the formatted number if necessary
		if (decimalPlaces > 0 && !formattedNumber.includes('.')) {
			formattedNumber += '.';
			for (let i = 0; i < decimalPlaces; i++) {
				formattedNumber += '0';
			}
		}

		// Construct the final string with prefix, number, suffix, and sign
		return prefix + (num < 0 ? '-' : '') + formattedNumber + symbol;
	}
}
