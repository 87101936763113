import { LoggerService } from '../services';
import { Injector, inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AuthenticationService } from '../authentication';

/**
 * *Permission Guard to prevent access of user
 *
 * @date 13 July 2023
 * @developer Rahul Kundu
 */
export const permissionsGuard: CanActivateFn = (route, state): boolean => {
	const _injector = inject(Injector);
	const _router: Router = _injector.get(Router);
	const _logger: LoggerService = _injector.get(LoggerService);
	const _authService: AuthenticationService = _injector.get(
		AuthenticationService
	);

	if (route.data && route.data['module']) {
		const module = route.data['module'];

		const _hasPermission = hasPermission(module, _authService);

		if (_hasPermission) {
			return true;
		}

		//no access provided, redirect to dashboard page
		_logger.error('Access forbidden, redirecting...');
		_router.navigate(['/dashboard']);
		return true;
	}

	return true;
};

/**
 * *Checks if the user has the necessary permissions to access the specified module.
 *
 * @param moduleName - The name of the module to check permissions for.
 * @param _authService - The AuthenticationService instance used for permission checks.
 * @returns A boolean indicating whether the user has permission to access the module.
 *
 * @date 18 July 2023
 * @developer Rahul Kundu
 */
const hasPermission = (
	moduleName: string,
	_authService: AuthenticationService
): boolean => {
	const navigationMenu = _authService.getNavigation();

	// Flatten the navigation menu and store it in a variable
	const flattenedMenu = navigationMenu.flatMap((menu) => [
		menu,
		...(menu.items || [])
	]);

	// Check if the moduleName exists in the flattened menu
	return flattenedMenu.some((menu) => menu.module === moduleName);
};
