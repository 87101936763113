import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';

//Pipes imports
import {
	SortByPipe,
	SummaryPipe,
	FileSizePipe,
	OrderTypePipe,
	AggregatePipe,
	MomentDatePipe,
	CapitalizePipe,
	TimePeriodPipe,
	OrderStatusPipe,
	FormatPhonePipe,
	ShrinkNumberPipe,
	NameInitialsPipe,
	SafeSanitizePipe,
	PaymentStatusPipe,
	FormatSecondsPipe,
	MomentTimeAgoPipe,
	AverageRatingPipe,
	MomentTimeDiffPipe,
	UrlFileExtensionPipe
} from './pipes';

//Directives imports
import {
	DigitOnlyDirective,
	UppercaseDirective,
	MiddleClickDirective,
	FocusControlDirective,
	ClickOutsideDirective,
	ImageFallbackDirective,
	AutocompleteOffDirective,
	DragAndDropFileDirective,
	LayoutBreakpointDirective,
	FocusInvalidControlDirective,
	CdkPlaceholderHeightDirective,
	PreventSubmitOnEnterDirective,
	NgModelDebounceChangeDirective
} from './directives';

//Components imports

const PIPES = [
	SortByPipe,
	SummaryPipe,
	FileSizePipe,
	OrderTypePipe,
	AggregatePipe,
	MomentDatePipe,
	CapitalizePipe,
	TimePeriodPipe,
	OrderStatusPipe,
	FormatPhonePipe,
	NameInitialsPipe,
	ShrinkNumberPipe,
	SafeSanitizePipe,
	PaymentStatusPipe,
	FormatSecondsPipe,
	MomentTimeAgoPipe,
	AverageRatingPipe,
	MomentTimeDiffPipe,
	UrlFileExtensionPipe
];

const DIRECTIVES = [
	UppercaseDirective,
	DigitOnlyDirective,
	MiddleClickDirective,
	FocusControlDirective,
	ClickOutsideDirective,
	ImageFallbackDirective,
	AutocompleteOffDirective,
	DragAndDropFileDirective,
	LayoutBreakpointDirective,
	FocusInvalidControlDirective,
	CdkPlaceholderHeightDirective,
	PreventSubmitOnEnterDirective,
	NgModelDebounceChangeDirective
];

@NgModule({
	exports: [...PIPES, ...DIRECTIVES],
	imports: [CommonModule, RouterModule],
	declarations: [...PIPES, ...DIRECTIVES]
})
export class SharedModule {}
