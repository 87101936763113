import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'sortBy'
})
export class SortByPipe implements PipeTransform {
	/**
	 * *sorts an array based on column value
	 *
	 * @param array
	 * @param column
	 * @returns sorted array
	 * @date 21 January 2023
	 * @developer Rahul Kundu
	 */
	public transform(array: any[], column: string): any[] {
		return array.sort((a: any, b: any) => {
			const columnA = a[column];
			const columnB = b[column];

			if (typeof columnA === 'string' && typeof columnB === 'string') {
				return columnA.localeCompare(columnB, 'en', {
					caseFirst: 'upper',
					sensitivity: 'case'
				});
			} else {
				if (columnA < columnB) return -1;
				if (columnA > columnB) return 1;
				return 0;
			}
		});
	}
}
