import { ToastrService } from 'ngx-toastr';
import { formatSizeUnits, uploadedFilevalidation } from '../utilities';
import {
	Input,
	Output,
	Directive,
	HostBinding,
	HostListener,
	EventEmitter
} from '@angular/core';

@Directive({
	selector: '[dragAndDrop]'
})
export class DragAndDropFileDirective {
	constructor(private _toastr: ToastrService) {}

	@Input('accept') accept: string[] = [];
	@Input('multiple') multiple: boolean = false;
	@Input('max-file-size') maxFileSize: number = 1e6;
	@HostBinding('class.drop-zone-file-over') fileOver!: boolean;
	@Output() onSelect: EventEmitter<FileList> = new EventEmitter<FileList>();

	/**
	 * File drag over event
	 *
	 * @param event
	 * @date 21 December 2022
	 * @developer Rahul Kundu
	 */
	@HostListener('dragover', ['$event'])
	onDragOver(event: DragEvent): void {
		event.preventDefault();
		event.stopPropagation();
		this.fileOver = true;
	}

	/**
	 * *File drag leave event
	 *
	 * @param event
	 * @date 21 December 2022
	 * @developer Rahul Kundu
	 */
	@HostListener('dragleave', ['$event'])
	public onDragLeave(event: DragEvent): void {
		event.preventDefault();
		event.stopPropagation();
		this.fileOver = false;
	}

	/**
	 * *On drop files to drop zone
	 *
	 * @param event
	 * @date 21 December 2022
	 * @developer Rahul Kundu
	 */
	@HostListener('drop', ['$event'])
	public ondropFile(event: DragEvent): void {
		event.preventDefault();
		event.stopPropagation();
		this.fileOver = false;
		const dataTransfer: DataTransfer | null = event.dataTransfer;
		if (!!dataTransfer) {
			const files = dataTransfer.files;
			if (files.length > 0) {
				this.handleUploadFiles(files);
			}
		}
	}

	/**
	 * *Handling validation on file drop to dropzone
	 *
	 * @param files
	 * @date 21 December 2022
	 * @developer Rahul Kundu
	 */
	private handleUploadFiles(files: FileList): void {
		if (!this.multiple) {
			if (files.length === 1) {
				const fileSize = this.maxFileSize / (1024 * 1024);
				const fileSizeUnit = this.maxFileSize < 1 ? 'kb' : 'mb';

				const validationResult = uploadedFilevalidation(
					files,
					fileSize,
					fileSizeUnit,
					this.accept
				);

				if (validationResult.length > 0) {
					const invalidFile = validationResult[0];
					if (invalidFile._isSizeExceeds) {
						this._toastr.error(
							`Uploaded File likely exceeds the maximum size of ${formatSizeUnits(
								this.maxFileSize
							)}`
						);
					} else if (!invalidFile._isMatchedExt) {
						const extensions = this.accept
							.map((ext) => ext.toUpperCase())
							.join(' | ');
						this._toastr.error(
							`Uploaded file must be of one of the types ${extensions}`
						);
					}
				} else {
					this.onSelect.emit(files);
				}
			} else {
				this._toastr.error(
					'Maximum number of files exceeded, limit is 1 at most.'
				);
			}
		}
	}
}
