import { appSettings } from '@app/configs';
import { Injectable } from '@angular/core';
import { Observable, mergeMap, of, timer } from 'rxjs';
import { PreloadingStrategy, Route } from '@angular/router';

@Injectable()
export class PreloadModulesStrategy implements PreloadingStrategy {
	/**
	 * *Preloads lazy-loaded routes based on the provided route object and the observable function.
	 *
	 * @param {Route} route - The route object containing information about the route being preloaded.
	 * @param {() => Observable<Function>} fn - The function that returns an observable to initiate the preloading.
	 * @returns An observable that initiates the preloading.
	 *
	 * @date 05 December 2022
	 * @developer Rahul Kundu
	 */
	public preload(
		route: Route,
		fn: () => Observable<Function>
	): Observable<Function | null> {
		//Proceeds with preloading
		if (route.data && route.data['preload']) {
			// Default delay in milliseconds (0.5 seconds)
			if (route.data['shouldDelay']) {
				const loadDelay =
					route.data['preloadTimeout'] ?? appSettings.preloadDelayMS;
				return timer(loadDelay).pipe(
					mergeMap(() => fn()) // Preload the module after the delay
				);
			}

			return fn();
		} else {
			//Proceeds without preloading
			return of(null);
		}
	}
}
