import { HttpService } from './http';
import { TitleStrategy } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { AuthenticationService } from './authentication';
import { HTTPInterceptorProvider } from './interceptors';
import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule, TitleCasePipe } from '@angular/common';
import { EnsureModuleLoadedOnceGuard } from './ensureModuleLoadedOnceGuard';

//Services import
import {
	SocketService,
	CommonService,
	LoggerService,
	CountriesService,
	WindowRefService,
	FileImportService,
	FileExportService,
	CloudinaryService,
	LibraryLoaderService,
	GoogleGeoCoderService,
	NavigationMenuService,
	PrimeNgUtilityService,
	GoogleAutocompleteService
} from './services';

//Strategies import
import {
	TitleStrategyService,
	PreloadModulesStrategy,
	NetworkAwarePreloadModulesStrategy
} from './strategies';

const PROVIDERS = [
	HttpService,
	LoggerService,
	CommonService,
	TitleCasePipe,
	SocketService,
	WindowRefService,
	CountriesService,
	FileImportService,
	FileExportService,
	CloudinaryService,
	LibraryLoaderService,
	PrimeNgUtilityService,
	GoogleGeoCoderService,
	AuthenticationService,
	NavigationMenuService,
	PreloadModulesStrategy,
	GoogleAutocompleteService,
	NetworkAwarePreloadModulesStrategy,
	{
		provide: TitleStrategy,
		useClass: TitleStrategyService
	}
];

@NgModule({
	declarations: [],
	exports: [HttpClientModule],
	imports: [CommonModule, HttpClientModule],
	providers: [...PROVIDERS, ...HTTPInterceptorProvider]
})
export class CoreModule extends EnsureModuleLoadedOnceGuard {
	/**
	 * !Ensuring that CoreModule is only loaded into AppModule
	 * *Looks for the module in the parent injector to see if it's already been loaded (only load once)
	 *
	 * @param module
	 * @date 05 December 2022
	 * @developer Rahul Kundu
	 */
	constructor(@Optional() @SkipSelf() module: CoreModule) {
		super(module);
	}
}
