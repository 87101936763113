import {
	style,
	query,
	group,
	state,
	trigger,
	animate,
	transition
} from '@angular/animations';

/**
 * *Fade In Out Animation
 *
 * @date 05 December 2022
 * @developer Rahul Kundu
 */
export const fadeAnimation = trigger('fadeAnimation', [
	state('void', style({ opacity: 0 })),
	transition('void => *, * => void', [animate(200)])
]);

/**
 * *Fade In Out Router Animation
 *
 * @date 05 December 2022
 * @developer Rahul Kundu
 */
export const routerAnimation = trigger('routerAnimation', [
	transition('* <=> *', [
		query(':enter, :leave', style({ opacity: 0 }), {
			optional: true
		}),
		group([
			query(
				':enter',
				[
					style({ opacity: 0 }),
					animate('0.1s ease-in-out', style({ opacity: 1 }))
				],
				{ optional: true }
			),
			query(
				':leave',
				[
					style({ opacity: 1 }),
					animate('0.1s ease-in-out', style({ opacity: 0 }))
				],
				{ optional: true }
			)
		])
	])
]);

/**
 * *Slide In Out Router Animation
 *
 * @date 05 December 2022
 * @developer Rahul Kundu
 */
export const slideInOut = trigger('slideInOut', [
	transition(':enter', [
		style({ transform: 'translateY(10px)', opacity: 0 }),
		animate(
			'200ms ease-in',
			style({ transform: 'translateY(0)', opacity: 1 })
		)
	]),
	transition(':leave', [
		animate(
			'200ms ease-in',
			style({ transform: 'translateY(10px)', opacity: 0 })
		)
	])
]);

/**
 * *height Animation
 *
 * @date 05 December 2022
 * @developer Rahul Kundu
 */
export const toggleHeight = trigger('toggleHeight', [
	transition(':enter', [
		style({ height: '0', opacity: 0 }),
		animate(
			'400ms cubic-bezier(0.86, 0, 0.07, 1)',
			style({ height: '*', opacity: 1 })
		)
	]),
	transition(':leave', [
		animate(
			'400ms cubic-bezier(0.86, 0, 0.07, 1)',
			style({ height: 0, opacity: 0 })
		)
	])
]);
