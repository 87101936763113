export const environment = {
	production: true,
	googleUa: 'G-YJ0FYNSCCL',
	cloudinaryCloudName: 'foodjets',
	cloudinaryKey: '971816547642271',
	cloudinaryUploadPreset: 'bf0ob2uf',
	host: 'https://admin-api.craveble.com/api',
	cloudinaryUploadFolder: 'cravings_of_the_week',
	frontendSocketHost: 'wss://api.craveble.com/ws',
	adminSocketHost: 'wss://admin-api.craveble.com/ws',
	googleApiKey: 'AIzaSyCaRgjkFQwePzWVMpcQ614kylCnmb_hFqo',
	supportLoginUrl: 'https://craveble.com/token-login?token=',
	pullCommunicationUrl: 'https://pull-communication-kpmyuuhoxq-uc.a.run.app/',
	powerBiReportKey:
		'eyJrIjoiZmY3MzAwNzctMzg5NS00NDIxLWEzNGEtMjA1YmYyNTNlNTczIiwidCI6ImZkNzRkZDJmLTczMGQtNGFkNC1hMjEzLTYzOWI3ZmFmOGJhNiIsImMiOjZ9',
	sentryDsn:
		'https://fd046aae950f4d71a6f8db471b4ae2f5@o992476.ingest.sentry.io/5993272',
	cloudinaryUrl:
		'https://res.cloudinary.com/foodjets/image/fetch/c_scale,w_100,q_auto:good/',
	carrierLabelUrl:
		'https://storage.googleapis.com/craveble-prod-carriers-label/',
	restaurantImageBucketUrl:
		'https://storage.googleapis.com/craveble-prod-restaurant-image/',
	categoryImageBucketUrl:
		'https://storage.googleapis.com/craveble-prod-category-image/',
	menuImageBucketUrl:
		'https://storage.googleapis.com/craveble-prod-item-image/',
	customerImageBucketUrl:
		'https://storage.googleapis.com/craveble-prod-customer-image/',
	fcmFilesBucketUrl:
		'https://storage.googleapis.com/craveble-prod-fcm-files/',
	contentBucketUrl:
		'https://storage.googleapis.com/craveble-prod-creative-image/'
};
