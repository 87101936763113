import { RouterOutlet } from '@angular/router';
import { routerAnimation } from '@shared/animations';
import { AfterViewInit, Component, OnInit } from '@angular/core';

@Component({
	animations: [routerAnimation],
	selector: 'crave-auth-layout',
	templateUrl: './crave-auth-layout.component.html',
	styleUrls: ['./crave-auth-layout.component.css']
})
export class CraveAuthLayoutComponent implements OnInit, AfterViewInit {
	constructor() {}

	public isLoadingFirstTime = true;

	ngOnInit(): void {}

	ngAfterViewInit(): void {
		/**
		 * *Disabling router animation first time on page load
		 *
		 * @date 05 December 2022
		 * @developer Rahul Kundu
		 */
		setTimeout(() => {
			this.isLoadingFirstTime = false;
		}, 300);
	}

	/**
	 * *Getting router state for animation
	 *
	 * @date 05 December 2022
	 * @developer Rahul Kundu
	 */
	public getRouterOutletState(outlet: RouterOutlet) {
		return outlet.isActivated ? outlet.activatedRoute : '';
	}
}
