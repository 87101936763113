import { HttpService } from '../http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, map, mergeMap, Observable, timer } from 'rxjs';
import {
	IFileType,
	ISignedUrl,
	IFileStatus,
	ISignedUrlResponse,
	IFileStatusDataset
} from '@shared/models';

@Injectable()
export class FileImportService {
	constructor(private _http: HttpService) {}

	private isRerenderItems: BehaviorSubject<IFileType | null> =
		new BehaviorSubject<IFileType | null>(null);
	public _isRerenderItemsSource$ = this.isRerenderItems.asObservable();

	private isCheckImportStatus: BehaviorSubject<IFileType | null> =
		new BehaviorSubject<IFileType | null>(null);
	public _importStatusSource$ = this.isCheckImportStatus.asObservable();

	/**
	 * *Sets value to the subject to re-render list after import
	 *
	 * @param value
	 * @date 22 December 2022
	 * @developer Rahul Kundu
	 */
	public setToRerenderItems(value: IFileType | null): void {
		this.isRerenderItems.next(value);
	}

	/**
	 * *Sets value to subject after successful import
	 *
	 * @param value
	 * @date 22 December 2022
	 * @developer Rahul Kundu
	 */
	public setOnImportSucess(value: IFileType | null): void {
		this.isCheckImportStatus.next(value);
	}

	/**
	 * *Generates signin url for imports
	 *
	 * @param prefix
	 * @returns Observable<ISignedUrl | null>
	 * @date 22 December 2022
	 * @developer Rahul Kundu
	 */
	public getImportSignedUrl(prefix: string): Observable<ISignedUrl | null> {
		return this._http.get(`auth/generate-signed-url/${prefix}`).pipe(
			map((apiResult) => {
				if (!!apiResult.error) {
					return null;
				} else {
					return apiResult.dataset;
				}
			})
		);
	}

	/**
	 * *Sets import url using google
	 *
	 * @param signedUrl
	 * @param file
	 * @returns Observable<ISignedUrlResponse>
	 * @date 22 December 2022
	 * @developer Rahul Kundu
	 */
	public setImportSignedUrl(
		signedUrl: string,
		file: File
	): Observable<ISignedUrlResponse> {
		return new Observable<ISignedUrlResponse>((observer) => {
			fetch(signedUrl, {
				method: 'PUT',
				headers: {
					'Content-Type': 'application/octet-stream'
				},
				body: file
			})
				.then((apiResult) => {
					let response!: ISignedUrlResponse;

					if (apiResult.status == 200) {
						response = {
							isSuccess: true
						};
					} else {
						response = {
							isSuccess: false
						};
					}
					observer.next(response);
					observer.complete();
				})
				.catch((error) => {
					observer.error(error);
				});
		});
	}

	/**
	 * *Gets import status
	 *
	 * @param suffix
	 * @param period
	 * @returns Observable<IFileStatusDataset | null>
	 * @date 22 December 2022
	 * @developer Rahul Kundu
	 */
	public getImportStatus(
		suffix: string,
		delay: number = 3000,
		period: number = 5000
	): Observable<IFileStatusDataset | null> {
		/**
		 * !Doing this shitty manupulation becasue of
		 * !Wired response dataset keys and api endpoints keys
		 */
		const regex = /^(.+?)(?:-(.+?))?$/;
		const matches = suffix.match(regex);
		if (!matches) {
			throw new Error(`Invalid suffix: ${suffix}`);
		}

		const apiSuffix = matches[1];
		const datasetSuffix = matches[2] || apiSuffix;

		return timer(delay, period).pipe(
			mergeMap(() =>
				this._http.get(`${apiSuffix}/get-import-status`).pipe(
					map((apiResult) => {
						if (!!apiResult.error) {
							return {
								status: 'error' as IFileStatus,
								message: apiResult.error.message
							};
						} else {
							if (!!apiResult.dataset) {
								return apiResult.dataset[
									datasetSuffix
								] as IFileStatusDataset;
							} else {
								return null;
							}
						}
					})
				)
			)
		);
	}
}
