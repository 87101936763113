import { Directive, ElementRef, Input, OnInit } from '@angular/core';

@Directive({
	selector: '[cdkPlaceholderHeight]'
})
export class CdkPlaceholderHeightDirective implements OnInit {
	constructor(private _elementRef: ElementRef<HTMLElement>) {}

	@Input('cdkPlaceholderHeight') parentElementRef!: HTMLElement;

	ngOnInit(): void {
		if (this.parentElementRef) {
			this.setPlaceholderHeight();
		}
	}

	/**
	 * *Calculate the height of the parent element and adjust the height of the placeholder element.
	 * *Takes into account the top and bottom border widths of the parent element.
	 *
	 * @date 30 June 2023
	 * @developer Rahul Kundu
	 */
	private setPlaceholderHeight(): void {
		const parentHeight = this.parentElementRef.offsetHeight;
		const borderTopWidth =
			parseInt(
				getComputedStyle(this.parentElementRef).borderTopWidth,
				10
			) || 0;
		const borderBottomWidth =
			parseInt(
				getComputedStyle(this.parentElementRef).borderBottomWidth,
				10
			) || 0;
		const adjustedHeight =
			parentHeight - borderTopWidth - borderBottomWidth;
		this._elementRef.nativeElement.style.height = `${adjustedHeight}px`;
	}
}
