import { Injectable } from '@angular/core';

@Injectable()
export class LoggerService {
	/**
	 * *Outputs a message to the Web Console.
	 *
	 * @param message A JavaScript string containing zero or more substitution strings.
	 * @param optionalParams A list of JavaScript objects to output
	 * @date 05 December 2022
	 * @developer Rahul Kundu
	 */
	public log: (message?: any, ...optionalParams: any[]) => void;

	/**
	 * *Outputs a debugging message to the Web Console.
	 *
	 * @param message A JavaScript string containing zero or more substitution strings.
	 * @param optionalParams A list of JavaScript objects to output
	 * @date: 26 August 2022
	 * @developer: Rahul Kundu
	 */
	public debug: (message?: any, ...optionalParams: any[]) => void;

	/**
	 * *Outputs an informational message to the Web Console.

	 *
	 * @param message A JavaScript string containing zero or more substitution strings.
	 * @param optionalParams A list of JavaScript objects to output
	 * @date 05 December 2022
	 * @developer Rahul Kundu
	 */
	public info: (message?: any, ...optionalParams: any[]) => void;

	/**
	 * *Outputs a warning message to the Web Console.

	 *
	 * @param message A JavaScript string containing zero or more substitution strings.
	 * @param optionalParams A list of JavaScript objects to output
	 * @date 05 December 2022
	 * @developer Rahul Kundu
	 */
	public warn: (message?: any, ...optionalParams: any[]) => void;

	/**
	 * *Outputs an error message to the Web Console.

	 *
	 * @param message A JavaScript string containing zero or more substitution strings.
	 * @param optionalParams A list of JavaScript objects to output
	 * @date 05 December 2022
	 * @developer Rahul Kundu
	 */
	public error: (message?: any, ...optionalParams: any[]) => void;

	constructor() {
		// console.log
		if (console && console.log) {
			this.log = console.log.bind(console);
		} else {
			this.log = () => {
				// Nothing
			};
		}

		// console.debug
		if (console && console.debug) {
			this.debug = console.debug.bind(console);
		} else {
			this.debug = () => {
				// Nothing
			};
		}

		// console.info
		if (console && console.info) {
			this.info = console.info.bind(console);
		} else {
			this.info = () => {
				// Nothing
			};
		}

		// console.warn
		if (console && console.warn) {
			this.warn = console.warn.bind(console);
		} else {
			this.warn = () => {
				// Nothing
			};
		}

		// console.error
		if (console && console.error) {
			this.error = console.error.bind(console);
		} else {
			this.error = () => {
				// Nothing
			};
		}
	}
}
