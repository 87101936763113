import { Component } from '@angular/core';
import { Toast, ToastPackage, ToastrService } from 'ngx-toastr';

@Component({
	selector: 'crave-custom-toast',
	templateUrl: './crave-custom-toast.component.html',
	styleUrls: ['./crave-custom-toast.component.css']
})
export class CraveCustomToastComponent extends Toast {
	constructor(
		public override toastPackage: ToastPackage,
		protected override toastrService: ToastrService
	) {
		super(toastrService, toastPackage);
	}
}
