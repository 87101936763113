import { FormGroup } from '@angular/forms';
import { CommonService } from '@core/services';
import {
	Input,
	OnDestroy,
	Directive,
	ElementRef,
	HostListener
} from '@angular/core';

@Directive({
	selector: '[focusInvalidControl]'
})
export class FocusInvalidControlDirective implements OnDestroy {
	constructor(
		private _el: ElementRef,
		private _commonService: CommonService
	) {}

	@Input() formGroup!: FormGroup;

	/**
	 * *On submit scrolling to first invalid control
	 *
	 * @param event
	 * @date 24 December 2022
	 * @developer Rahul Kundu
	 */
	@HostListener('submit', ['$event'])
	public onSubmit(event: Event): void {
		if (!!this.formGroup && this.formGroup.invalid) {
			for (const key of Object.keys(this.formGroup.controls)) {
				if (this.formGroup.controls[key].invalid) {
					const invalidControl = this._el.nativeElement.querySelector(
						'[formcontrolname="' + key + '"]'
					);
					this._commonService.setElementToScroll(invalidControl);
					setTimeout(() => {
						invalidControl.focus();
					}, 400);
					break;
				}
			}
		}
	}

	ngOnDestroy(): void {
		this._commonService.setElementToScroll(null);
	}
}
