import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'formatSeconds'
})
export class FormatSecondsPipe implements PipeTransform {
	/**
	 * *Pipe to format seconds to hour, minute seconds
	 *
	 * @param time
	 *
	 * @returns string
	 * @date 02 February 2023
	 * @developer Rahul Kundu
	 */
	public transform(time: number | null): string | null {
		if (!!time) {
			const hour = Math.floor(time / 3600);
			const minutes = Math.floor((time % 3600) / 60);
			const seconds = Math.floor((time % 3600) % 60);

			const hourDisplay =
				hour > 0
					? hour +
					  (hour == 1 ? ' Hour' : ' Hours') +
					  (minutes > 0 || seconds > 0 ? ', ' : '')
					: '';

			const minutesDisplay =
				minutes > 0
					? minutes +
					  (minutes == 1 ? ' Minute' : ' Minutes') +
					  (seconds > 0 ? ', ' : '')
					: '';

			const secondsDisplay =
				seconds > 0
					? seconds + (seconds == 1 ? ' Second' : ' Seconds')
					: '';

			return hourDisplay + minutesDisplay + secondsDisplay;
		}

		return null;
	}
}
