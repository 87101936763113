import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { cloudinaryConfig } from '@app/configs';
import { ICloudinaryResponse } from '@shared/models';

@Injectable()
export class CloudinaryService {
	constructor() {}

	/**
	 * *Uploads a file to Cloudinary using the fetch API.
	 *
	 * @param file The File object to be uploaded.
	 * @returns An Observable emitting the Cloudinary upload response or error.
	 *
	 * @date 28 November 2023
	 * @developer Rahul Kundu
	 */
	public uploadFileToCloudinary(file: File) {
		const url = `https://api.cloudinary.com/v1_1/${environment.cloudinaryCloudName}/auto/upload`;

		const sanitizedFileName = file.name.replace(/[^a-zA-Z0-9]+/g, '-');

		const cloudinaryFormData = new FormData();

		cloudinaryFormData.append('file', file);
		cloudinaryFormData.append('api_key', environment.cloudinaryKey);
		cloudinaryFormData.append('folder', environment.cloudinaryUploadFolder);
		cloudinaryFormData.append(
			'upload_preset',
			environment.cloudinaryUploadPreset
		);
		cloudinaryFormData.append(
			'public_id',
			sanitizedFileName + '-' + Date.now()
		);

		return new Observable<ICloudinaryResponse>((observer) => {
			fetch(url, {
				method: 'POST',
				body: cloudinaryFormData
			})
				.then((response) => {
					return response.json();
				})
				.then((uploadResult) => {
					const thumbnailUrl = `https://res.cloudinary.com/${environment.cloudinaryCloudName}/video/upload/so_2.5/w_${uploadResult.width},h_${uploadResult.height},q_100/${uploadResult.public_id}.png`;

					observer.next({
						...uploadResult,
						thumbnail_url: thumbnailUrl
					});
				})
				.catch((error) => {
					const cloudinaryError = {
						error: {
							message:
								error.message ||
								'An error occurred during the upload.'
						}
					};
					observer.error(cloudinaryError);
				})
				.finally(() => observer.complete());
		});
	}
}
