import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { SidebarModule } from 'primeng/sidebar';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { PanelMenuModule } from 'primeng/panelmenu';
import { SharedModule } from '@shared/shared.module';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { NgModule, Optional, SkipSelf } from '@angular/core';
import { throwIfAlreadyLoaded } from './ensureModuleLoadedOnceGuard';

// Crave auth layout & components imports
import {
	CraveAuthLayoutComponent,
	CraveAdminHeaderComponent,
	CraveAdminLayoutComponent,
	CraveAdminNavbarComponent,
	CraveAdminSidebarComponent
} from './layouts';

const MODULES = [
	CommonModule,
	SharedModule,
	RouterModule,
	SidebarModule,
	DragDropModule,
	PanelMenuModule,
	NgScrollbarModule
];

const COMPONENTS = [
	CraveAuthLayoutComponent,
	CraveAdminHeaderComponent,
	CraveAdminLayoutComponent,
	CraveAdminNavbarComponent,
	CraveAdminSidebarComponent
];

@NgModule({
	imports: [...MODULES],
	exports: [...COMPONENTS],
	declarations: [...COMPONENTS]
})
export class TemplateModule {
	/**
	 * !Ensuring that TemplateModule is only loaded into AppModule
	 * *Looks for the module in the parent injector to see if it's already been loaded (only load once)
	 *
	 * @param module
	 * @date 05 December 2022
	 * @developer Rahul Kundu
	 */
	constructor(@Optional() @SkipSelf() module: TemplateModule) {
		throwIfAlreadyLoaded(module, 'TemplateModule');
	}
}
