import { Observable } from 'rxjs';
import cityTimezones from 'city-timezones';
import { IPlace } from '@shared/models';
import { Injectable, NgZone } from '@angular/core';

@Injectable()
export class GoogleGeoCoderService {
	/**
	 * *The underlying google.maps.Geocoder object
	 *
	 * @see https://developers.google.com/maps/documentation/javascript/reference/geocoder#Geocoder
	 */
	private readonly _geocoder = new google.maps.Geocoder();

	constructor(private _ngZone: NgZone) {}

	/**
	 * *Wrapper for `geocode` request, that is being sent to Google servers.
	 *
	 * @see https://developers.google.com/maps/documentation/javascript/reference/geocoder#Geocoder.geocode
	 * @returns Observable<google.maps.GeocoderResult[]>
	 * @date 25 September 2022
	 * @developer Rahul Kundu
	 */
	public geocode(
		request: google.maps.GeocoderRequest
	): Observable<google.maps.GeocoderResult[]> {
		return new Observable<google.maps.GeocoderResult[]>((observer) => {
			this._geocoder.geocode(request, (results, status) => {
				// need to manually trigger ngZone because "geocode" callback is not picked up properly by Angular
				this._ngZone.run(() => {
					if (status === google.maps.GeocoderStatus.OK) {
						// if status is "OK", the response contains a valid GeocoderResponse.
						observer.next(!!results ? results : []);
						observer.complete();
					} else if (
						status === google.maps.GeocoderStatus.ZERO_RESULTS
					) {
						observer.next([]);
						observer.complete();
					} else {
						observer.error(status);
					}
				});
			});
		});
	}

	/**
	 * *Formats a geo location
	 *
	 * @param result
	 * @returns IPlace
	 * @date 25 September 2022
	 * @developer Rahul Kundu
	 */
	public formatGeocodeResult(result: google.maps.GeocoderResult): IPlace {
		let zone: string = '';
		let city: string = '';
		let latitude!: number;
		let longitude!: number;
		let state: string = '';
		let street: string = '';
		let country: string = '';
		let address: string = '';
		let locality: string = '';
		let timezone: string = '';
		let stateCode: string = '';
		let postalCode: string = '';
		let streetNumber: string = '';

		const addressComponents = result.address_components;

		if (!!addressComponents) {
			addressComponents.forEach(
				(component: google.maps.GeocoderAddressComponent) => {
					component.types.forEach((type: string) => {
						switch (type) {
							case 'neighborhood':
								zone = component.long_name;
								break;
							case 'locality':
								city = component.long_name;
								break;
							case 'country':
								country = component.long_name;
								break;
							case 'route':
								street = component.short_name;
								break;
							case 'sublocality_level_1':
								locality = component.long_name;
								break;
							case 'administrative_area_level_1':
								state = component.long_name;
								stateCode = component.short_name;
								break;
							case 'postal_code':
								postalCode = component.long_name;
								break;
							case 'street_number':
								streetNumber = component.short_name;
								break;
						}
					});
				}
			);

			if (!!result.geometry && result.geometry.location) {
				latitude = result.geometry.location.lat();
				longitude = result.geometry.location.lng();
			}

			if (!!city) {
				const timeZoneLookUps = cityTimezones.lookupViaCity(city);
				const filteredTimezone = timeZoneLookUps.find(
					(timeZone) => timeZone.country.indexOf(country) !== -1
				);

				timezone = !!filteredTimezone
					? filteredTimezone.timezone
					: 'America/Los_Angeles';
			}

			address = `${streetNumber} ${street}, ${city}, ${stateCode} ${postalCode}, ${country}`;
		}

		const place: IPlace = {
			zone: !!zone ? zone : null,
			city: !!city ? city : null,
			state: !!state ? state : null,
			street: !!street ? street : null,
			country: !!country ? country : null,
			address: !!address ? address : null,
			latitude: !!latitude ? latitude : null,
			timezone: !!timezone ? timezone : null,
			longitude: !!longitude ? longitude : null,
			state_code: !!stateCode ? stateCode : null,
			postalCode: !!postalCode ? postalCode : null,
			streetNumber: !!streetNumber ? streetNumber : null,
			placeId: !!result.place_id ? result.place_id : null,
			description: !!result.formatted_address
				? result.formatted_address
				: null
		};

		return place;
	}
}
