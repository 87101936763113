import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'summary'
})
export class SummaryPipe implements PipeTransform {
	/**
	 * *Pipe to turncate long texts
	 *
	 * Example:
	 *      text | summary : 450 : isCollapsed
	 *      returns lorem ipsu...
	 * @param value : A long javasript string
	 * @param limit : A javasript number to turncate value
	 * @param isCollapsed : boolean to toggle full text | short text
	 * @returns turncated text
	 *
	 * @date 05 December 2022
	 * @developer Rahul Kundu
	 */
	public transform(
		value: string,
		limit: number,
		completeWords = false,
		isCollapsed?: boolean
	): string {
		if (!value) return '';

		if (isCollapsed) return value;

		if (value.length <= limit) {
			return value;
		}

		const elipses = '...';
		let truncateLimit = limit;

		if (completeWords) {
			truncateLimit = value.substring(0, limit).lastIndexOf(' ');
		}

		const truncatedText = value.substring(0, truncateLimit);

		return truncatedText + elipses;
	}
}
