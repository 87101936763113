import { appSettings } from '@app/configs';
import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TitleStrategy, RouterStateSnapshot } from '@angular/router';

const APP_TITLE = appSettings.appTitle;
const SEPARATOR = appSettings.appTitleSeperator;

@Injectable()
export class TitleStrategyService extends TitleStrategy {
	constructor(private _title: Title) {
		super();
	}

	/**
	 * *Custom title strategy to overwride browser title
	 *
	 * @param snapshot
	 * @date 05 December 2022
	 * @developer Rahul Kundu
	 */
	public override updateTitle(snapshot: RouterStateSnapshot): void {
		const title = this.buildTitle(snapshot);

		if (title) {
			this._title.setTitle(`${APP_TITLE}${SEPARATOR}${title}`);
		}
	}
}
