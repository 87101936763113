import { Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { distinctUntilChanged } from 'rxjs/operators';
import { IAuthUser, IBreadcrumb } from '@shared/models';
import { NgProgress, NgProgressRef } from 'ngx-progressbar';
import { AuthenticationService } from '@core/authentication';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonService, WindowRefService } from '@core/services';
import {
	Router,
	Event,
	NavigationEnd,
	ActivatedRoute,
	PRIMARY_OUTLET,
	ActivatedRouteSnapshot
} from '@angular/router';

@Component({
	selector: 'crave-admin-header',
	templateUrl: './crave-admin-header.component.html',
	styleUrls: ['./crave-admin-header.component.css']
})
export class CraveAdminHeaderComponent implements OnInit, OnDestroy {
	constructor(
		private _router: Router,
		private _progress: NgProgress,
		private _toastr: ToastrService,
		private _windowRef: WindowRefService,
		private _commonService: CommonService,
		private _activatedRoute: ActivatedRoute,
		private _authService: AuthenticationService
	) {
		this.initHomeCrumb();
		this.initBreadcrumb();
		this.getUserFromCookie();
		this.window = this._windowRef.nativeWindow;
	}

	public pageTitle!: string;
	public isDropdownOpen = false;
	public homeCrumb!: IBreadcrumb;
	private progressRef!: NgProgressRef;
	public userDetails!: IAuthUser | null;
	public window!: { [key: string]: any };
	public breadcrumbs: IBreadcrumb[] = [];
	private subscriptions: Subscription[] = [];

	ngOnInit(): void {
		this.progressRef = this._progress.ref('progress-bar');
	}

	/**
	 * *Toggles profile dropdown on click
	 *
	 * @date 04 July 2022
	 * @developer Rahul Kundu
	 */
	public toggleDropdown(event: MouseEvent): void {
		event.preventDefault();
		this.isDropdownOpen = !this.isDropdownOpen;
	}

	/**
	 * *Hides profile dropdown on click outside
	 *
	 * @date 05 December 2022
	 * @developer Rahul Kundu
	 */
	public onClickOutside(): void {
		if (this.isDropdownOpen) {
			this.isDropdownOpen = false;
		}
	}

	/**
	 * *On logout user
	 *
	 * @param event
	 * @date 05 December 2022
	 * @developer Rahul Kundu
	 */
	public onLogout(event: MouseEvent) {
		event.preventDefault();

		this.progressRef.start();
		this.subscriptions.push(
			this._authService.logout().subscribe({
				next: (apiResult) => {
					this.progressRef.complete();

					if (apiResult.error) {
						this._toastr.error(apiResult.error.message);
					} else {
						if (apiResult.status) {
							this.userDetails = null;
							this.isDropdownOpen = false;
							this._router.navigate(['/']);
						}
					}
				},
				error: (apiError) => {
					this.progressRef.complete();
					this._toastr.error(apiError.message);
				}
			})
		);
	}

	/**
	 * *On chnage sidebat visibility state on click
	 *
	 * @param event
	 * @date 04 July 2023
	 * @developer Rahul Kundu
	 */
	public onChangeSidebarState(event: MouseEvent): void {
		event.preventDefault();
		this._commonService.setSidebarVisibilityState(true);
	}

	/**
	 * *Breadcrumb for dashboard page
	 *
	 * @date 05 December 2022
	 * @developer Rahul Kundu
	 */
	private initHomeCrumb(): void {
		this.homeCrumb = {
			label: 'dashboard',
			routerLink: '/dashboard'
		};
	}

	/**
	 * *Initializing breadcrumb on navigation end
	 *
	 * @date 05 December 2022
	 * @developer Rahul Kundu
	 */
	private initBreadcrumb() {
		this.subscriptions.push(
			this._router.events.pipe(distinctUntilChanged()).subscribe({
				next: (event: Event) => {
					if (event instanceof NavigationEnd) {
						const route = this._router.routerState.snapshot.root;
						this.getPageTitle(route, event.url);
						const breadcrumbs = this.getBreadcrumbs(
							this._activatedRoute.root
						);

						this.breadcrumbs = breadcrumbs;
					}
				}
			})
		);
	}

	/**
	 * *Generating page title
	 *
	 * @param url
	 * @date 05 December 2022
	 * @developer Rahul Kundu
	 */
	private getPageTitle(route: ActivatedRouteSnapshot, url: string): void {
		const ROUTE_DATA_PAGE_TITLE = 'page_title';
		const routeData = this.lastRouteChild(route).data;

		if (routeData.hasOwnProperty(ROUTE_DATA_PAGE_TITLE)) {
			this.pageTitle = routeData[ROUTE_DATA_PAGE_TITLE];
		} else {
			const title = url.split('/').pop() as string;
			this.pageTitle = title.replace('-', ' ');
		}
	}

	/**
	 * Recursively build breadcrumb according to activated route.
	 *
	 * @param route
	 * @param url
	 * @param breadcrumbs
	 * @date 05 December 2022
	 * @developer Rahul Kundu
	 */
	private getBreadcrumbs(
		route: ActivatedRoute,
		url: string = '',
		breadcrumbs: IBreadcrumb[] = []
	): IBreadcrumb[] {
		const ROUTE_DATA_BREADCRUMB = 'breadcrumb';
		const children: ActivatedRoute[] = route.children;

		if (children.length === 0) {
			return breadcrumbs;
		}

		for (const child of children) {
			if (child.outlet !== PRIMARY_OUTLET) {
				continue;
			}

			const routeData = child.snapshot.data;

			if (!routeData.hasOwnProperty(ROUTE_DATA_BREADCRUMB)) {
				return this.getBreadcrumbs(child, url, breadcrumbs);
			}

			const routeURL: string = child.snapshot.url
				.map((segment) => segment.path)
				.join('/');

			url += `/${routeURL}`;

			const breadcrumb: IBreadcrumb = {
				routerLink: url,
				queryParams: child.snapshot.queryParams,
				label: routeData[ROUTE_DATA_BREADCRUMB]
			};

			if (!!breadcrumb.label) {
				const isAlreadyExist = breadcrumbs.some(
					(_breadcrumb) =>
						_breadcrumb.label === routeData[ROUTE_DATA_BREADCRUMB]
				);

				if (!isAlreadyExist) breadcrumbs.push(breadcrumb);
			}

			return this.getBreadcrumbs(child, url, breadcrumbs);
		}

		return [];
	}

	/**
	 * *Gets last child from a active route
	 *
	 * @returns ActivatedRouteSnapshot
	 * @date 05 December 2022
	 * @developer Rahul Kundu
	 */
	private lastRouteChild(
		route: ActivatedRouteSnapshot
	): ActivatedRouteSnapshot {
		if (route.firstChild) {
			return this.lastRouteChild(route.firstChild);
		} else {
			return route;
		}
	}

	/**
	 * *Getting user info from cookie
	 *
	 * @date 05 December 2022
	 * @developer Rahul Kundu
	 */
	private getUserFromCookie(): void {
		this.userDetails = this._authService.getUserInfo();
	}

	ngOnDestroy(): void {
		this.subscriptions.forEach((subscription) =>
			subscription.unsubscribe()
		);
	}
}
