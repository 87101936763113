import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'formatPhone'
})
export class FormatPhonePipe implements PipeTransform {
	/**
	 * *Pipe to format phone number
	 *
	 * @param number
	 * @param appendDialCode
	 * @returns string
	 * @date 30 January 2023
	 * @developer Rahul Kundu
	 */
	public transform(number: string, appendDialCode = true): string {
		if (!!+number) {
			if (number.includes('+1')) number = number.replace('+1', '');
			const cleanedNumber = ('' + number).replace(/\D/g, '');
			const formattedNumber = cleanedNumber.match(
				/^(\d{3})(\d{3})(\d{4})$/
			);
			if (formattedNumber) {
				const dialCode = '+1 ';
				const phoneNumber =
					'(' +
					formattedNumber[1] +
					') ' +
					formattedNumber[2] +
					'-' +
					formattedNumber[3];

				return appendDialCode ? dialCode + phoneNumber : phoneNumber;
			}
			return '--';
		}

		return '--';
	}
}
