import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { INavMenu } from '@shared/models';
import { appSettings } from '@app/configs';
import { NgScrollbar } from 'ngx-scrollbar';
import { toggleHeight } from '@shared/animations';
import {
	OnInit,
	ViewChild,
	OnDestroy,
	Component,
	AfterViewInit
} from '@angular/core';

@Component({
	selector: 'crave-admin-navbar',
	templateUrl: './crave-admin-navbar.component.html',
	styleUrls: ['./crave-admin-navbar.component.css'],
	animations: [toggleHeight]
})
export class CraveAdminNavbarComponent
	implements OnInit, AfterViewInit, OnDestroy
{
	constructor(private _router: Router) {
		this.initNavigation();
	}

	public menus: INavMenu[] = [];
	public isLoadingFirstTime = true;
	private subscriptions: Subscription[] = [];
	public currentYear = new Date().getFullYear();
	@ViewChild(NgScrollbar) scrollbarRef!: NgScrollbar;
	private navCredentials: string = appSettings.navCredentialsKey;

	ngOnInit(): void {
		this.onRouteNavigationEnd();
	}

	ngAfterViewInit(): void {
		setTimeout(() => {
			if (this.isLoadingFirstTime) {
				const activeMenuEl: HTMLElement | null =
					document.querySelector('.crave-nav-open') ||
					document.querySelector('.crave-nav-active');

				if (!!activeMenuEl) {
					const offset = activeMenuEl.offsetTop - 10;

					this.scrollbarRef.scrollTo({
						duration: 0,
						top: offset
					});
				}

				this.isLoadingFirstTime = false;
			}
		}, 100);
	}

	/**
	 * *Updating navigation sub menus dropdown active state
	 *
	 * @date 14 December 2022
	 * @developer Rahul Kundu
	 */
	private onRouteNavigationEnd() {
		const url = this._router.url;

		this.menus.forEach((menu) => {
			if (!!menu.items && menu.items.length > 0) {
				let isRouteMatched: boolean = menu.items.some(
					(submenu) => url.indexOf(submenu.routerLink) !== -1
				);

				isRouteMatched
					? ((menu.expanded = true), (menu.isActiveRoute = true))
					: ((menu.expanded = false), (menu.isActiveRoute = false));
			}
		});
	}

	/**
	 * *On toggle navigation menu
	 *
	 * @param event
	 * @param selected
	 * @date 05 December 2022
	 * @developer Rahul Kundu
	 */
	public onToggleMenu(event: MouseEvent, selected: INavMenu): void {
		event.preventDefault();

		for (const menu of this.menus) {
			if (menu.id === selected.id) {
				menu.expanded = !menu.expanded;
			} else {
				menu.expanded = false;
			}
		}
	}

	/**
	 * *Sets active state for submenu items
	 *
	 * @param event
	 * @param subMenu
	 * @date 15 December 2022
	 * @developer Rahul Kundu
	 */
	public onClickSubMenu(event: MouseEvent, subMenu: INavMenu): void {
		event.preventDefault();

		this.menus.forEach((menu) => {
			if (!!menu.items && menu.items.length > 0) {
				menu.items.forEach((_submenu) => {
					if (_submenu.id === subMenu.id) {
						_submenu.isSubmenuActive = true;
					} else {
						_submenu.isSubmenuActive = false;
					}
				});
			}
		});
	}

	/**
	 * *Initializing navigation menus
	 *
	 * @date 05 December 2022
	 * @developer Rahul Kundu
	 */
	private initNavigation(): void {
		const navInStorage = localStorage.getItem(this.navCredentials);
		if (!!navInStorage) {
			this.menus = JSON.parse(navInStorage) as INavMenu[];
		}
	}

	ngOnDestroy(): void {
		this.subscriptions.forEach((subscription) =>
			subscription.unsubscribe()
		);
	}
}
