import { Injector, inject } from '@angular/core';
import { LoggerService } from '@core/services';
import { Router, CanActivateFn } from '@angular/router';
import { AuthenticationService } from '@core/authentication';

/**
 * *Auth Guard to prevent unauthorized user
 *
 * @date 13 July 2023
 * @developer Rahul Kundu
 */
export const authGuard: CanActivateFn = (route, state): boolean => {
	const _injector = inject(Injector);
	const _router: Router = _injector.get(Router);
	const _logger: LoggerService = _injector.get(LoggerService);
	const _authService: AuthenticationService = _injector.get(
		AuthenticationService
	);

	const isLoggedIn = _authService.isAuthenticated();

	if (isLoggedIn) {
		// authorized, so return true
		return true;
	}

	// not logged in, redirect to login page
	_logger.error('Not authenticated, redirecting...');
	_router.navigate(['/sign-in']);
	return false;
};
