import { HttpService } from '../http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, map, Observable } from 'rxjs';
import {
	IMenu,
	IZone,
	ICategory,
	IWarehouse,
	IRestaurant,
	ICategoryTagsDataset
} from '@shared/models';

@Injectable()
export class CommonService {
	constructor(private _http: HttpService) {}

	private _elementToScroll: BehaviorSubject<
		HTMLElement | HTMLInputElement | null
	> = new BehaviorSubject<HTMLElement | HTMLInputElement | null>(null);
	public elementToScrollSource$ = this._elementToScroll.asObservable();

	private _isSidebarVisible: BehaviorSubject<boolean> =
		new BehaviorSubject<boolean>(false);
	public sidebarVisibilityState$ = this._isSidebarVisible.asObservable();

	/**
	 * *Setting value to subject for scroll to function
	 *
	 * @param element
	 * @date 24 December 2022
	 * @developer Rahul Kundu
	 */
	public setElementToScroll(
		element: HTMLElement | HTMLInputElement | null
	): void {
		this._elementToScroll.next(element);
	}

	/**
	 * *Setting value to subject for sidebar visibility state
	 *
	 * @param isVisible
	 * @date 04 July 2023
	 * @developer Rahul Kundu
	 */
	public setSidebarVisibilityState(isVisible: boolean): void {
		this._isSidebarVisible.next(isVisible);
	}

	/**
	 * *Fetching restaurants list
	 *
	 * @param searchValue
	 * @returns Observable<IRestaurant[] | null>
	 * @date 15 December 2022
	 * @developer Rahul Kundu
	 */
	public fetchRestaurants(
		searchValue: string = ''
	): Observable<IRestaurant[] | null> {
		const payload = {
			search_key: searchValue
		};

		return this._http.post('restaurant/list', payload).pipe(
			map((apiResult) => {
				if (!!apiResult.error) return null;

				const restaurantsDataset = apiResult.dataset;
				return restaurantsDataset.restaurant_list as IRestaurant[];
			})
		);
	}

	/**
	 * *Fetching zones list
	 *
	 * @returns Observable<IZone[] | null>
	 * @date 16 December 2022
	 * @developer Rahul Kundu
	 */
	public fetchZones(): Observable<IZone[] | null> {
		return this._http.post('zone/list').pipe(
			map((apiResult) => {
				if (!!apiResult.error) {
					return null;
				} else {
					return apiResult.dataset;
				}
			})
		);
	}

	/**
	 * *Fetching menus list
	 *
	 * @returns Observable<IMenu[] | null>
	 * @date 16 December 2022
	 * @developer Rahul Kundu
	 */
	public fetchMenus(searchValue: string = ''): Observable<IMenu[] | null> {
		const payload = {
			search_key: searchValue
		};
		return this._http.post('menu/list', payload).pipe(
			map((apiResult) => {
				if (!!apiResult.error) {
					return null;
				} else {
					return apiResult.dataset.menu_list;
				}
			})
		);
	}

	/**
	 * *Fetching categories list
	 *
	 * @param searchValue
	 * @returns Observable<ICategoryTagsDataset | null>
	 * @date 28 December 2022
	 * @developer Rahul Kundu
	 */
	public fetchCategories(
		searchValue: string = ''
	): Observable<ICategoryTagsDataset | null> {
		return this._http
			.post('category/list', {
				search_key: searchValue,
				sort_by: 'rank'
			})
			.pipe(
				map((apiResult) => {
					if (!!apiResult.error) {
						return null;
					} else {
						const categories = apiResult.dataset
							.category_list as ICategory[];

						const categoriesDataset: ICategoryTagsDataset = {
							categories
						};

						return categoriesDataset;
					}
				})
			);
	}

	/**
	 * *Fetching parent categories list
	 *
	 * @returns Observable<ICategory[] | null>
	 * @date 28 December 2022
	 * @developer Rahul Kundu
	 */
	public fetchParentCategories(): Observable<ICategory[] | null> {
		return this._http.get('category/get-parent-categories').pipe(
			map((apiResult) => {
				if (!!apiResult.error) {
					return null;
				} else {
					return apiResult.dataset.parent_category_list;
				}
			})
		);
	}

	/**
	 * *Fetching child categories list
	 *
	 * @returns Observable<ICategory[] | null>
	 * @date 22 January 2023
	 * @developer Rahul Kundu
	 */
	public fetchChildCategories(): Observable<ICategory[] | null> {
		return this._http.get('category/get-child-categories').pipe(
			map((apiResult) => {
				if (!!apiResult.error) {
					return null;
				} else {
					return apiResult.dataset.child_category_list;
				}
			})
		);
	}

	/**
	 * *Fetching all categories list
	 *
	 * @returns Observable<ICategory[] | null>
	 * @date 13 July 2023
	 * @developer Rahul Kundu
	 */
	public fetchAllCategories(): Observable<ICategory[] | null> {
		return this._http.get('category/get-all-categories').pipe(
			map((apiResult) => {
				if (!!apiResult.error) {
					return null;
				} else {
					return apiResult.dataset.category_list;
				}
			})
		);
	}

	/**
	 * *Fetching warehouses list
	 *
	 * @returns Observable<IWarehouse[] | null>
	 * @date 16 January 2023
	 * @developer Rahul Kundu
	 */
	public fetchWarehouses(
		searchValue: string = ''
	): Observable<IWarehouse[] | null> {
		return this._http
			.post('warehouse/list', {
				search_key: searchValue
			})
			.pipe(
				map((apiResult) => {
					if (!!apiResult.error) {
						return null;
					} else {
						return apiResult.dataset;
					}
				})
			);
	}
}
