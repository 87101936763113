import { IFeedbackItem } from '@shared/models';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'averageRating'
})
export class AverageRatingPipe implements PipeTransform {
	/**
	 * *Pipe to calculate the average rating based on the provided ratings array.
	 *
	 * @param ratings - The array of rating objects, each containing a 'rating' property.
	 * @returns The calculated average rating.
	 * @date 07 August 2023
	 * @developer Rahul Kundu
	 */
	public transform(items: IFeedbackItem[]): number {
		// Return 0 if there are no ratings
		if (!items || items.length === 0) {
			return 0;
		}

		const totalRatings = items.reduce((sum, item) => sum + item.rating, 0);

		const averageRating = totalRatings / items.length;

		return averageRating;
	}
}
