import { Observable } from 'rxjs';
import { IResponse } from '@shared/models';
import { Injectable } from '@angular/core';
import { isObject } from '@shared/utilities';
import { environment } from '@env/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';

type IRequestType = 'DELETE' | 'GET' | 'HEAD' | 'POST' | 'PATCH' | 'PUT';
interface IRequestOptions {
	useUrlPrefix?: boolean;
	headers?: any;
	params?: any;
	observe?: 'body' | 'events' | 'response';
	reportProgress?: boolean;
}

@Injectable()
export class HttpService {
	constructor(private _http: HttpClient) {}

	/**
	 * *Service for making backend calls
	 *
	 * @param method - request method
	 * @param url - request url
	 * @param params - request params
	 * @param options - request extra options
	 * @returns obserable
	 *
	 * @date 05 December 2022
	 * @developer Rahul Kundu
	 */
	private request(
		method: IRequestType,
		url: string,
		params?: any,
		options?: IRequestOptions
	): Observable<IResponse> {
		let data;
		let reqUrl = url;
		let request$: Observable<any>;
		let reqOptions: any = { useUrlPrefix: true };

		if (isObject(options)) reqOptions = Object.assign(reqOptions, options);

		// Creating request headers
		if (reqOptions.headers) {
			reqOptions.headers = new HttpHeaders(reqOptions.headers);
		}

		// Assigninig params
		if (isObject(params)) {
			if (params instanceof FormData) {
				data = params;
				reqOptions.reportProgress = reqOptions.reportProgress || true;
			} else {
				data = params;
			}
		}

		// Checking url prefix
		if (reqOptions.useUrlPrefix === true) {
			reqUrl = environment.host + '/' + url;
		}

		if (method === 'GET') {
			reqOptions.params = data;
		} else {
			reqOptions.body = {
				params: data
			};
		}

		reqOptions.observe = reqOptions.observe || 'body';

		// Final Request
		request$ = this._http.request(method, reqUrl, reqOptions);

		return request$;
	}

	/**
	 * *HTTP Post request
	 *
	 * @param url - request url
	 * @param params - request params
	 * @param options - request extra options
	 * @returns obserable
	 *
	 * @date 05 December 2022
	 * @developer Rahul Kundu <r.kundu5@gmail.com>
	 */
	public post(
		url: string,
		params?: any,
		options?: IRequestOptions
	): Observable<IResponse> {
		return this.request('POST', url, params, options);
	}

	/**
	 * *HTTP Put request
	 *
	 * @param url - request url
	 * @param params - request params
	 * @param options - request extra options
	 * @returns obserable
	 *
	 * @date 05 December 2022
	 * @developer Rahul Kundu <r.kundu5@gmail.com>
	 */
	public put(
		url: string,
		params?: any,
		options?: IRequestOptions
	): Observable<IResponse> {
		return this.request('PUT', url, params, options);
	}

	/**
	 * *HTTP Patch request
	 *
	 * @param url - request url
	 * @param params - request params
	 * @param options - request extra options
	 * @returns obserable
	 *
	 * @date 05 December 2022
	 * @developer Rahul Kundu <r.kundu5@gmail.com>
	 */
	public patch(
		url: string,
		params?: any,
		options?: IRequestOptions
	): Observable<IResponse> {
		return this.request('PATCH', url, params, options);
	}

	/**
	 * *HTTP Get request
	 *
	 * @param url - request url
	 * @param options - request extra options
	 * @returns obserable
	 *
	 * @date 05 December 2022
	 * @developer Rahul Kundu <r.kundu5@gmail.com>
	 */
	public get(
		url: string,
		params?: any,
		options?: IRequestOptions
	): Observable<IResponse> {
		return this.request('GET', url, params, options);
	}

	/**
	 * *HTTP Delete request
	 *
	 * @param url - request url
	 * @param params - request params
	 * @param options - request extra options
	 * @returns obserable
	 *
	 * @date 05 December 2022
	 * @developer Rahul Kundu <r.kundu5@gmail.com>
	 */
	public delete(
		url: string,
		params?: any,
		options?: IRequestOptions
	): Observable<IResponse> {
		return this.request('DELETE', url, params, options);
	}
}
