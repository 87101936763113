import { HttpService } from '@core/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, map, mergeMap, Observable, timer } from 'rxjs';
import { IFileStatus, IFileStatusDataset, IFileType } from '@shared/models';

@Injectable()
export class FileExportService {
	constructor(private _http: HttpService) {}

	private isCheckExportStatus: BehaviorSubject<IFileType | null> =
		new BehaviorSubject<IFileType | null>(null);
	public _exportStatusSource$ = this.isCheckExportStatus.asObservable();

	/**
	 * *Sets value to subject after successful export
	 *
	 * @param value
	 * @date 23 December 2022
	 * @developer Rahul Kundu
	 */
	public setOnExportSucess(value: IFileType | null): void {
		this.isCheckExportStatus.next(value);
	}

	/**
	 * *Gets export status
	 *
	 * @param suffix
	 * @param period
	 * @returns Observable<IFileStatusDataset | null>
	 * @date 23 December 2022
	 * @developer Rahul Kundu
	 */
	public getExportStatus(
		suffix: string,
		delay: number = 3000,
		period: number = 5000
	): Observable<IFileStatusDataset | null> {
		return timer(delay, period).pipe(
			mergeMap(() =>
				this._http.get(`restaurant/get-export-status`).pipe(
					map((apiResult) => {
						if (!!apiResult.error) {
							return {
								status: 'error' as IFileStatus,
								message: apiResult.error.message
							};
						} else {
							if (!!apiResult.dataset) {
								return apiResult.dataset[
									suffix
								] as IFileStatusDataset;
							} else {
								return null;
							}
						}
					})
				)
			)
		);
	}
}
