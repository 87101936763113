import moment from 'moment';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'timePeriod'
})
export class TimePeriodPipe implements PipeTransform {
	/**
	 * *Transforms a pair of start and end dates into a descriptive time period string.
	 * *Returns a string indicating the duration between two dates, such as "since yesterday",
	 * *"since last week", "since last 2 weeks", "since last month", or "since last year".
	 *
	 * @param startDate - The start date of the time period.
	 * @param endDate - The end date of the time period.
	 * @returns A string indicating the descriptive time period.
	 *
	 * @date 19 February 2024
	 * @developer Rahul Kundu
	 */
	public transform(startDate: Date, endDate: Date): string {
		// Use Moment.js to compare dates and calculate time difference
		const endMoment = moment(endDate);
		const startMoment = moment(startDate);

		if (
			startMoment.isSame(endMoment, 'day') ||
			endMoment.diff(startMoment, 'days') === 1
		) {
			return 'since yesterday';
		}

		if (endMoment.diff(startMoment, 'weeks') < 1) {
			return 'since last week';
		}

		if (endMoment.diff(startMoment, 'months') < 1) {
			const weeks = endMoment.diff(startMoment, 'weeks');
			return `since last ${weeks === 1 ? 'week' : `${weeks} weeks`}`;
		}

		if (endMoment.diff(startMoment, 'years') < 1) {
			const months = endMoment.diff(startMoment, 'months');
			return `since last ${months === 1 ? 'month' : `${months} months`}`;
		}

		const years = endMoment.diff(startMoment, 'years');
		return `since last ${years === 1 ? 'year' : `${years} years`}`;
	}
}
