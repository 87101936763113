<ng-container *ngIf="window['innerWidth'] > 1200">
	<div class="crave-navigation-outer">
		<crave-admin-navbar></crave-admin-navbar>
	</div>
</ng-container>

<!-- responsive sidebar start -->
<p-sidebar
	[appendTo]="'body'"
	[draggable]="false"
	[position]="'left'"
	[blockScroll]="true"
	[dismissible]="true"
	[closeOnEscape]="false"
	[showCloseIcon]="false"
	[(visible)]="isSidebarVisible"
	[styleClass]="'custom-sidebar custom-navigation-sidebar'"
	[transitionOptions]="'0.25s cubic-bezier(0.250, 0.460, 0.450, 0.940)'"
>
	<div class="custom-sidebar-content">
		<div class="custom-sidebar-close">
			<a (click)="onCloseSidebar($event)">
				<span class="material-symbols-rounded"> close </span>
			</a>
		</div>
		<crave-admin-navbar></crave-admin-navbar>
	</div>
</p-sidebar>
<!-- responsive sidebar end -->
