import { IOrderStatus } from '../models';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'orderType'
})
export class OrderTypePipe implements PipeTransform {
	/**
	 * *Pipe to get order type
	 *
	 * @param status
	 * @returns string
	 * @date 06 December 2022
	 * @developer Rahul Kundu
	 */
	public transform(status: IOrderStatus): string {
		let className: string = '';

		switch (status) {
			case 'order_complete':
			case 'refunded_credit':
				className = 'success';
				break;
			case 'label_generated':
			case 'order_processing':
				className = 'warning';
				break;
			case 'order_in_transit':
				className = 'info';
				break;
			case 'order_canceled':
				className = 'error';
				break;
		}

		return className;
	}
}
