import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { HttpService } from '@core/http';
import { appSettings } from '@app/configs';
import { Injectable } from '@angular/core';
import { StatusCodes } from 'http-status-codes';
import { CookieService } from 'ngx-cookie-service';
import { NavigationMenuService } from '../services';
import {
	INavMenu,
	IResponse,
	IAuthUser,
	IAuthResult,
	IAuthCookie
} from '@shared/models';

@Injectable()
export class AuthenticationService {
	constructor(
		private _http: HttpService,
		private _cookieService: CookieService,
		private _menuService: NavigationMenuService
	) {}

	private credentials: string = appSettings.credentialsKey;
	private navCredentials: string = appSettings.navCredentialsKey;

	/**
	 * *Authenticates with the selected strategy
	 * *Stores user info in the cookie
	 *
	 * @param payload
	 * @returns Observable<IResponse>
	 * @date 05 December 2022
	 * @developer Rahul Kundu
	 */
	public authenticate(payload: {
		email: string;
		password: string;
	}): Observable<IResponse> {
		return this._http.post('auth/login', payload).pipe(
			tap((apiResult) => {
				const authData = apiResult.dataset as IAuthResult;
				if (apiResult.status === StatusCodes.OK && !apiResult.error) {
					const storageData: IAuthCookie = {
						token: authData.token,
						user: {
							account_type: authData.account_type,
							email: authData.email,
							name: authData.name,
							phone: authData.phone,
							role_id: authData.role_id,
							uid: authData.uid
						}
					};

					const navigation = this._menuService.initNavigation(
						authData.navigation_menu
					);

					localStorage.removeItem(this.navCredentials);
					localStorage.setItem(
						this.navCredentials,
						JSON.stringify(navigation)
					);

					this._cookieService.delete(this.credentials);
					this._cookieService.set(
						this.credentials,
						JSON.stringify(storageData),
						{
							path: '/'
						}
					);
				}
			})
		);
	}

	/**
	 * *On Forget password
	 *
	 * @param email
	 * @returns Observable<IResponse>
	 *
	 * @date 05 December 2022
	 * @developer Rahul Kundu
	 */
	public forgetPassword(email: string): Observable<IResponse> {
		return this._http.post('auth/forget-pwd', { email });
	}

	/**
	 * *Reset user password
	 *
	 * @param payload
	 * @returns Observable<IResponse>
	 *
	 * @date 05 December 2022
	 * @developer Rahul Kundu
	 */
	public resetPassword(payload: {
		key: string;
		password: string;
	}): Observable<IResponse> {
		return this._http.post('auth/reset-pwd', payload);
	}

	/**
	 * *Sign outs user
	 * *Removes details from the token cookie
	 *
	 * @returns observable of boolean
	 * @date 05 December 2022
	 * @developer Rahul Kundu
	 */
	public logout(): Observable<IResponse> {
		return this._http.get('auth/logout').pipe(
			tap((apiResult) => {
				if (apiResult.status === StatusCodes.OK) {
					this.clearUserInfo();
				}
			})
		);
	}

	/**
	 * *Returning current user detail from cookie
	 *
	 * @returns current logged in user
	 * @date 05 December 2022
	 * @developer Rahul Kundu
	 */
	public getUserInfo(): IAuthUser | null {
		const savedCredentials: IAuthCookie | null = this.getCookieData();
		return !!savedCredentials ? savedCredentials.user : null;
	}

	/**
	 * *Returning current user's navigation list from cookie
	 *
	 * @returns current user navigation
	 * @date 05 December 2022
	 * @developer Rahul Kundu
	 */
	public getNavigation(): INavMenu[] {
		const savedNavigation = localStorage.getItem(this.navCredentials);
		return !!savedNavigation ? JSON.parse(savedNavigation) : [];
	}

	/**
	 * *Removing current user detail from cookie
	 *
	 * @date 05 December 2022
	 * @developer Rahul Kundu
	 */
	public clearUserInfo(): void {
		localStorage.removeItem(this.navCredentials);
		this._cookieService.delete(this.credentials, '/');
	}

	/**
	 * *Getting current user token from storage
	 *
	 * @returns JWT Token
	 * @date 05 December 2022
	 * @developer Rahul Kundu
	 */
	public getAccessToken(): string | null {
		const savedCredentials: IAuthCookie | null = this.getCookieData();
		return !!savedCredentials ? savedCredentials.token : null;
	}

	/**
	 * *If user is authenticated
	 *
	 * @returns boolean if authenticated
	 * @date 05 December 2022
	 * @developer Rahul Kundu
	 */
	public isAuthenticated(): boolean {
		if (!!this.getCookieData()) {
			return true;
		}
		return false;
	}

	/**
	 * *Getting auth data from cookie
	 *
	 * @returns current user's data
	 * @date 05 December 2022
	 * @developer Rahul Kundu
	 */
	private getCookieData(): IAuthCookie | null {
		const cookieData = this._cookieService.get(this.credentials) as string;
		const savedCredentials: IAuthCookie = !!cookieData
			? JSON.parse(cookieData)
			: null;
		return savedCredentials;
	}
}
