import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { PreloadingStrategy, Route } from '@angular/router';

@Injectable()
export class NetworkAwarePreloadModulesStrategy implements PreloadingStrategy {
	/**
	 * *Preloads lazy-loaded routes based on the provided route object and the observable function,
	 * *taking into account the network connection of the user.
	 *
	 * @param {Route} route - The route object containing information about the route being preloaded.
	 * @param {() => Observable<Function>} fn - The function that returns an observable to initiate the preloading.
	 * @returns An observable that initiates the preloading.
	 *
	 * @date 05 December 2022
	 * @developer Rahul Kundu
	 */
	public preload(
		route: Route,
		fn: () => Observable<Function>
	): Observable<Function | null> {
		const connection = (navigator as any).connection;

		/**
		 * !if the browser doesn't support it
		 */
		if (!connection) {
			return of(null);
		}

		/**
		 * !if user has set a reduced data usage
		 */
		if (connection.saveData) {
			return of(null);
		}

		/**
		 * !if user's connection is slow
		 */
		const speed = connection.effectiveType;
		const slowConnections = ['slow-2g', '2g'];
		if (slowConnections.includes(speed)) {
			return of(null);
		}

		return fn();
	}
}
