import { Chips } from 'primeng/chips';
import { IOperation } from '@shared/models';
import { Calendar } from 'primeng/calendar';
import { Dropdown } from 'primeng/dropdown';
import { InputNumber } from 'primeng/inputnumber';
import { MultiSelect } from 'primeng/multiselect';
import { AutoComplete } from 'primeng/autocomplete';
import { Injectable, QueryList } from '@angular/core';
import { ConnectedOverlayScrollHandler } from 'primeng/dom';

@Injectable()
export class PrimeNgUtilityService {
	constructor() {}

	/**
	 * *Performs a series of operations on various PrimeNG components based on the specified list of operations.
	 *
	 * @param {IOperation[]} operations - An array of objects describing the operations to be performed on the PrimeNG components.
	 * Each object contains a `type` property specifying the type of operation and a `queryList` property containing a `QueryList` of the PrimeNG components to perform the operation on.
	 *
	 * Supported operations:
	 * - 'alignDropdownOnScroll': Aligns the position of a dropdown panel with respect to its trigger element when the panel is scrolled.
	 * - 'alignAutocompleteOnScroll': Aligns the position of an autocomplete panel with respect to its trigger element when the panel is scrolled.
	 * - 'alignMultiSelectOnScroll': Aligns the position of a multiselect panel with respect to its trigger element when the panel is scrolled.
	 * - 'alignCalendarOnScroll': Aligns the position of a calendar panel with respect to its trigger element when the panel is scrolled.
	 * - 'removeInputNumberAutocomplete': Removes the autocomplete functionality from inputNumber components.
	 * - 'removeChipsInputAutocomplete': Removes the autocomplete functionality from chips input components.
	 *
	 * Note: Any unsupported `type` values will result in a console error.
	 *
	 * @date 05 June 2023
	 * @developer Rahul Kundu
	 */
	public manipulatePrimeNgComponents(operations: IOperation[]): void {
		operations.forEach((operation) => {
			switch (operation.type) {
				case 'alignDropdownOnScroll':
					this.alignPrimeNgDropdownOnScroll(
						operation.queryList as QueryList<Dropdown>
					);
					break;
				case 'alignAutocompleteOnScroll':
					this.alignPrimeNgAutocompleteOnScroll(
						operation.queryList as QueryList<AutoComplete>
					);
					break;
				case 'alignMultiSelectOnScroll':
					this.alignPrimeNgMultiSelectOnScroll(
						operation.queryList as QueryList<MultiSelect>
					);
					break;
				case 'alignCalendarOnScroll':
					this.alignPrimeNgCalendarOnScroll(
						operation.queryList as QueryList<Calendar>
					);
					break;
				case 'removeInputNumberAutocomplete':
					this.removePrimeNgInputNumberAutoComplete(
						operation.queryList as QueryList<InputNumber>
					);
					break;
				case 'removeChipsInputAutocomplete':
					this.removePrimeNgChipsInputAutoComplete(
						operation.queryList as QueryList<Chips>
					);
					break;
				default:
					console.error(
						`Unsupported operation type: ${operation.type}`
					);
					break;
			}
		});
	}

	/**
	 * *Aligns the PrimeNG Dropdown component overlay on scroll.
	 *
	 * @param {QueryList<Dropdown>} instances - A query list of Dropdown instances to align on scroll.
	 *
	 * @date 05 June 2023
	 * @developer Rahul Kundu
	 */
	private alignPrimeNgDropdownOnScroll(instances: QueryList<Dropdown>): void {
		if (instances.length > 0) {
			instances.forEach((instance) => {
				if (!instance.scrollHandler) {
					instance.scrollHandler = new ConnectedOverlayScrollHandler(
						instance.containerViewChild.nativeElement,
						() => {
							if (instance.overlayVisible) {
								instance.alignOverlay();
							}
						}
					);
				}
				instance.scrollHandler.bindScrollListener();
			});
		}
	}

	/**
	 * *Aligns the PrimeNG Autocomplete component overlay on scroll.
	 *
	 * @param {QueryList<AutoComplete>} instances - A query list of AutoComplete instances to align on scroll.
	 *
	 * @date 05 June 2023
	 * @developer Rahul Kundu
	 */
	private alignPrimeNgAutocompleteOnScroll(
		instances: QueryList<AutoComplete>
	): void {
		if (instances.length > 0) {
			instances.forEach((instance) => {
				if (!instance.scrollHandler) {
					instance.scrollHandler = new ConnectedOverlayScrollHandler(
						instance.containerEL.nativeElement,
						(event: any) => {
							if (instance.overlayVisible) {
								instance.alignOverlay();
							}
						}
					);
				}
				instance.scrollHandler.bindScrollListener();
			});
		}
	}

	/**
	 * *Aligns the PrimeNG Calendar component overlay on scroll.
	 *
	 * @param { QueryList<Calendar>} instances - A query list of Calendar instances to align on scroll.
	 *
	 * @date 05 June 2023
	 * @developer Rahul Kundu
	 */
	private alignPrimeNgCalendarOnScroll(instances: QueryList<Calendar>): void {
		if (instances.length > 0) {
			instances.forEach((instance) => {
				if (!instance.scrollHandler) {
					instance.scrollHandler = new ConnectedOverlayScrollHandler(
						instance.containerViewChild.nativeElement,
						() => {
							if (instance.overlayVisible) {
								instance.alignOverlay();
							}
						}
					);
				}
				instance.scrollHandler.bindScrollListener();
			});
		}
	}

	/**
	 * *Aligns the PrimeNG MultiSelect component overlay on scroll.
	 *
	 * @param {QueryList<MultiSelect>} instances - A query list of MultiSelect instances to align on scroll.
	 *
	 * @date 05 June 2023
	 * @developer Rahul Kundu
	 */
	private alignPrimeNgMultiSelectOnScroll(
		instances: QueryList<MultiSelect>
	): void {
		if (instances.length > 0) {
			instances.forEach((instance) => {
				if (!instance.scrollHandler) {
					instance.scrollHandler = new ConnectedOverlayScrollHandler(
						instance.containerViewChild.nativeElement,
						(event: any) => {
							if (instance.overlayVisible) {
								instance.alignOverlay();
							}
						}
					);
				}
				instance.scrollHandler.bindScrollListener();
			});
		}
	}

	/**
	 * *Removes the autocomplete attribute from PrimeNG InputNumber components
	 * *Generates a new random token on each blur event.
	 *
	 * @param {QueryList<InputNumber>} instances - A query list of InputNumber instances to remove autocomplete.
	 *
	 * @date 05 June 2023
	 * @developer Rahul Kundu
	 */
	private removePrimeNgInputNumberAutoComplete(
		instances: QueryList<InputNumber>
	): void {
		if (instances.length > 0) {
			instances.forEach((instance) => {
				const inputEl = instance.input
					.nativeElement as HTMLInputElement;

				const setInputAttributes = () => {
					let randomString = Math.random().toString(36).slice(-6);
					inputEl.setAttribute('name', randomString);
					inputEl.setAttribute('autocomplete', randomString);
				};

				// set attributes on initial render
				setInputAttributes();

				// set attributes on blur
				inputEl.addEventListener('blur', setInputAttributes);
			});
		}
	}

	/**
	 * *Removes the autocomplete attribute from PrimeNG Chips components
	 * *Generates a new random token on each blur event.
	 *
	 * @param {QueryList<Chips>} instances - A query list of Chips instances to remove autocomplete.
	 *
	 * @date 05 June 2023
	 * @developer Rahul Kundu
	 */
	private removePrimeNgChipsInputAutoComplete(
		instances: QueryList<Chips>
	): void {
		if (instances.length > 0) {
			instances.forEach((instance) => {
				const chipsInputEl = instance.inputViewChild
					.nativeElement as HTMLInputElement;

				const setInputAttributes = () => {
					let randomString = Math.random().toString(36).slice(-6);
					chipsInputEl.setAttribute('name', randomString);
					chipsInputEl.setAttribute('autocomplete', randomString);
				};

				// set attributes on initial render
				setInputAttributes();

				// set attributes on blur
				chipsInputEl.addEventListener('blur', setInputAttributes);
			});
		}
	}
}
