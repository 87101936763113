<div class="crave-header-outer">
	<div class="crave-header-inner">
		<div class="crave-header-left">
			<ng-container *ngIf="window['innerWidth'] <= 1200">
				<div class="crave-header-sidebar-toggler">
					<a (click)="onChangeSidebarState($event)">
						<span class="icon-hamburger"></span>
					</a>
				</div>
			</ng-container>
			<div class="crave-header-left-content">
				<h3>{{ pageTitle }}</h3>
				<ng-container *ngIf="breadcrumbs.length > 0">
					<nav aria-label="breadcrumb">
						<ul class="breadcrumb">
							<li class="breadcrumb-item">
								<a [routerLink]="[homeCrumb.routerLink]">
									{{ homeCrumb.label }}
								</a>
							</li>
							<ng-container
								*ngFor="
									let breadcrumb of breadcrumbs.slice(
										0,
										breadcrumbs.length - 1
									);
									let index = index
								"
							>
								<li class="breadcrumb-item">
									<a
										[routerLink]="[breadcrumb.routerLink]"
										[queryParams]="breadcrumb.queryParams"
									>
										{{ breadcrumb.label }}
									</a>
								</li>
							</ng-container>
							<li
								class="breadcrumb-item active"
								aria-current="page"
							>
								{{ breadcrumbs[breadcrumbs.length - 1].label }}
							</li>
						</ul>
					</nav>
				</ng-container>
			</div>
		</div>
		<div class="crave-header-right">
			<ng-container *ngIf="!!userDetails">
				<div
					class="crave-user-info"
					[class.crave-user-profile-dropdown-open]="isDropdownOpen"
				>
					<a
						clickOutside
						(clickOutside)="onClickOutside()"
						(click)="toggleDropdown($event)"
					>
						<div class="crave-user-info-left">
							<img
								alt=""
								width="42"
								height="42"
								loading="lazy"
								src="./assets/images/user-no-image.svg"
							/>
						</div>
						<div class="crave-user-info-right">
							<p>hello</p>
							<h6>
								{{ userDetails.name.split(' ')[0] }}
							</h6>
						</div>
						<div class="crave-user-info-action">
							<span class="material-symbols-rounded">
								unfold_more
							</span>
						</div>
					</a>
					<ng-container *ngIf="isDropdownOpen">
						<div class="crave-user-profile-dropdown">
							<div class="crave-user-profile-info">
								<h6>
									{{ userDetails.name }}
								</h6>
								<p>{{ userDetails.email }}</p>
							</div>
							<div class="crave-user-profile-dropdown-menu">
								<ul>
									<li [routerLinkActive]="'active'">
										<a [routerLink]="['profile-settings']">
											<span class="icon-profile"></span>
											<p>my profile</p>
										</a>
									</li>
									<li>
										<a (click)="onLogout($event)">
											<span class="icon-logout"></span>
											<p>logout</p>
										</a>
									</li>
								</ul>
							</div>
						</div>
					</ng-container>
				</div>
			</ng-container>
		</div>
	</div>
</div>
