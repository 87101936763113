import { Router } from '@angular/router';
import { ToastrModule } from 'ngx-toastr';
import * as Sentry from '@sentry/angular-ivy';
import { AppComponent } from './app.component';
import { CommonModule } from '@angular/common';
import { CoreModule } from '@core/core.module';
import { ConfirmationService } from 'primeng/api';
import { NgProgressModule } from 'ngx-progressbar';
import { SharedModule } from '@shared/shared.module';
import { TemplateModule } from '@core/template.module';
import { AppRoutingModule } from './app-routing.module';
import { BrowserModule } from '@angular/platform-browser';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { CraveCustomToastComponent } from '@shared/components';
import { AppInitializerModule } from './core/app-initializer.module';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

@NgModule({
	declarations: [AppComponent, CraveCustomToastComponent],
	imports: [
		CoreModule,
		CommonModule,
		SharedModule,
		BrowserModule,
		TemplateModule,
		AppRoutingModule,
		NgProgressModule,
		ConfirmDialogModule,
		AppInitializerModule,
		BrowserAnimationsModule,
		ToastrModule.forRoot({
			maxOpened: 0,
			timeOut: 5000,
			closeButton: true,
			tapToDismiss: false,
			extendedTimeOut: 2000,
			preventDuplicates: true,
			toastClass: 'custom-toast-outer',
			positionClass: 'toast-bottom-center',
			toastComponent: CraveCustomToastComponent
		})
	],
	bootstrap: [AppComponent],
	providers: [
		ConfirmationService,
		// Use Sentry to handle and report errors
		{
			provide: ErrorHandler,
			useValue: Sentry.createErrorHandler({
				showDialog: false
			})
		},
		// Service for tracing and monitoring performance
		{
			provide: Sentry.TraceService,
			deps: [Router]
		},
		// Initialize Sentry tracing service during app initialization
		{
			provide: APP_INITIALIZER,
			useFactory: () => () => {},
			deps: [Sentry.TraceService],
			multi: true
		}
	]
})
export class AppModule {}
