import CryptoJS from 'crypto-js';
import moment from 'moment-timezone';
import { appSettings } from '@app/configs';
import { IFileValidation } from '../models';

/**
 * *Checking if a arg is object
 *
 * @param arg - object | array
 * @returns obecjt | boolean
 * @date 05 December 2022
 * @developer Rahul Kundu
 */
export function isObject(arg: any): arg is object {
	return typeof arg === 'object' && arg !== null;
}

/**
 * *Formatting bytes to human readble units
 *
 * @param fileSize : A javascript number contains file size in bytes
 * @date 05 December 2022
 * @developer Rahul Kundu
 */
export function formatSizeUnits(fileSize: number) {
	if (fileSize > 0) {
		const bytes: number = fileSize;
		const decimals = 2;

		if (bytes === 0) {
			return '0 Bytes';
		}

		const k = 1000;
		const dm = decimals < 0 ? 0 : decimals;
		const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

		const i = Math.floor(Math.log(bytes) / Math.log(k));

		return (
			parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
		);
	} else {
		return null;
	}
}

/**
 * *Random UUID generator
 *
 * @date 05 December 2022
 * @developer Rahul Kundu
 */
export function getGuid() {
	// Timestamp
	let d = new Date().getTime();
	// Time in microseconds since page-load or 0 if unsupported
	let d2 = (performance && performance.now && performance.now() * 1000) || 0;
	return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
		let r = Math.random() * 16; // random number between 0 and 16
		if (d > 0) {
			// Use timestamp until depleted
			r = (d + r) % 16 | 0;
			d = Math.floor(d / 16);
		} else {
			// Use microseconds since page-load if supported
			r = (d2 + r) % 16 | 0;
			d2 = Math.floor(d2 / 16);
		}
		return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
	});
}

/**
 * *Uploaded file size and extension validation
 *
 * @param files
 * @param filesize
 * @param filesiseType
 * @param fileTypes
 *
 * @date 05 December 2022
 * @developer Rahul Kundu
 */
export function uploadedFilevalidation(
	files: FileList,
	filesize: number,
	filesiseType: 'kb' | 'mb',
	fileTypes: string[]
): IFileValidation[] {
	filesize = filesize || 1;
	let validationInfo: IFileValidation[] = [];
	if (files.length > 0) {
		// Getting list of files
		for (let i = 0; i < files.length; i++) {
			const file = files[i];
			const _fileName = file.name; // file name
			const _fileSize = file.size; // file size
			const _fileTypes = fileTypes; // preferred extensions
			const _sizeInMB = file.size / (1024 * 1024);
			const _sizeInKB = file.size / 1024;

			const _fileExtension = _fileName
				.split('.')
				[_fileName.split('.').length - 1].toLowerCase(); // file extension

			const _isMatchedExt: boolean =
				_fileTypes.length > 0
					? _fileTypes.indexOf(_fileExtension) > -1
					: true;
			const _isSizeExceeds: boolean =
				filesiseType === 'mb'
					? _sizeInMB > filesize
					: _sizeInKB > filesize;

			// OR together the accepted extensions and NOT it. Then OR the size cond.
			if (!_isMatchedExt || _isSizeExceeds) {
				/**
				 * !avoid this due to Object Literal Shorthand Syntax
				 * ! _fileName: _fileName to _fileName
				 * !_fileSize: _fileSize to _fileSize
				 * *This rule enforces the use of the shorthand syntax
				 */
				validationInfo.push({
					_fileName,
					_fileSize,
					_isMatchedExt,
					_isSizeExceeds
				});
			}
		}
	}

	return validationInfo;
}

/**
 * *Filter to abbreviate a number
 *
 * @param value : A javasript number as parameter
 * @date 05 December 2022
 * @developer Rahul Kundu
 */
export function toAbbreviateNumber(value: number): string {
	let newValue: string | number = value;
	if (value >= 1000) {
		const suffixes: string[] = ['', 'k', 'm', 'b', 't'];
		const suffixNum: number = Math.floor(('' + value).length / 3);
		let shortValue: string | number = '';
		for (let precision = 2; precision >= 1; precision--) {
			shortValue = parseFloat(
				(suffixNum !== 0
					? value / Math.pow(1000, suffixNum)
					: value
				).toPrecision(precision)
			);
			const dotLessShortValue = (shortValue + '').replace(
				/[^a-zA-Z 0-9]+/g,
				''
			);
			if (dotLessShortValue.length <= 2) {
				break;
			}
		}
		if (typeof shortValue === 'number' && shortValue % 1 !== 0) {
			shortValue = shortValue.toFixed(1);
		}
		newValue = shortValue + suffixes[suffixNum];
	}
	return newValue.toString();
}

/**
 * *Function to check if a email is valid
 *
 * @param email
 * @date 05 December 2022
 * @developer Rahul Kundu
 * @returns boolean
 */
export function validateEmail(email: string): boolean {
	const re =
		/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return re.test(String(email).toLowerCase());
}

/**
 * *Formatting timestamp to desired format
 *
 * @param timestamp
 * @param returnType
 * @returns number | hour | minute | second
 *
 * @date 05 December 2022
 * @developer Rahul Kundu
 */
export function formattedTime(
	timestamp: number,
	returnType: 'hour' | 'minute' | 'second'
): number {
	const unix_timestamp = timestamp;
	const date = new Date(unix_timestamp * 1000);
	const hours = date.getHours();
	const minutes = date.getMinutes();
	const seconds = date.getSeconds();
	let toReturn!: number;

	switch (returnType) {
		case 'hour':
			toReturn = hours;
			break;
		case 'minute':
			toReturn = minutes;
			break;
		case 'second':
			toReturn = seconds;
			break;
		default:
			break;
	}

	return toReturn;
}

/**
 * *Checking if a string or number is a valid number
 * @param n
 * @returns boolean
 * @date 05 December 2022
 * @developer Rahul Kundu
 */
export function isNumber(n: string | number): boolean {
	return !isNaN(parseFloat(n.toString())) && isFinite(Number(n));
}

/**
 * *Formats a string in title case
 *
 * @param value
 * @returns string
 * @date 12 November 2022
 * @developer Rahul Kundu
 */
export function toTitleCase(value: string): string {
	return (value.split(/[\s,|_.-]+/) as string[])
		.map((term) => term.charAt(0).toUpperCase() + term.substring(1))
		.join(' ');
}

/**
 * *Formats a number to currency
 *
 * @param value
 * @param format
 * @returns string
 * @date 05 December 2022
 * @developer Rahul Kundu
 */
export function formatCurrency(value: number, format: 'IN' | 'US'): string {
	return new Intl.NumberFormat(`en-${format}`, {
		style: 'currency',
		currency: format === 'IN' ? 'INR' : 'USD',
		currencyDisplay: 'symbol',
		minimumFractionDigits: 0,
		maximumFractionDigits: 0
	})
		.format(value)
		.replace(/[$₹]/g, '')
		.trim();
}

/**
 * *Generates a hour range array
 *
 * @param interval
 * @param language
 * @returns IHourRange[]
 * @date 05 December 2022
 * @developer Rahul Kundu
 */
export function getHourRanges(
	interval: number,
	startHour: number = 9,
	language = window.navigator.language
): {
	id: number;
	time: string;
}[] {
	const date = new Date();
	const ranges = [];

	for (
		let index = 0, minutes = 0;
		minutes < 24 * 60;
		minutes = minutes + interval
	) {
		index++;
		date.setHours(startHour);
		date.setMinutes(minutes);
		ranges.push({
			id: index,
			time: date.toLocaleTimeString(language, {
				hour: 'numeric',
				minute: 'numeric'
			})
		});
	}

	return ranges;
}

/**
 * *Converting image to base 64 from file change event
 *
 * @param file
 * @returns base64 string
 * @date 23 December 2022
 * @developer Rahul Kundu
 */
export function getBase64(file: File): Promise<string> {
	return new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = () => resolve(reader.result as string);
		reader.onerror = (error) => reject(error);
	});
}

/**
 * *Checks if a string represents a Base64 data URI format.
 *
 * @param {string | null} str - The input value to check, which can be a string or null.
 * @returns {boolean} Returns true if the input string matches the Base64 data URI pattern; otherwise, returns false.
 */
export function isBase64(str: string | null): boolean {
	if (str === null) {
		return false; // Handle the case where str is null
	}

	const regex = /^(?:[data]{4}:(text|image|application)\/[a-z]*)/;
	return !!str.match(regex);
}

/**
 * *Converts date utc unix timestamp using timezone
 * @param date
 * @param timezone
 * @returns number
 * @date 24 December 2022
 * @developer Rahul Kundu
 */
export function dateToMomentTimestamp(date: Date, timezone: string): number {
	return !!date && !!timezone ? moment(date).tz(timezone).unix() : 0;
}

/**
 * *Converts unix timestamp to date using timezone
 * @param timestamp
 * @returns string
 * @date 24 December 2022
 * @developer Rahul Kundu
 */
export function momentTimestampToDate(timestamp: number): Date | string {
	return !!timestamp ? moment.unix(timestamp).toDate() : '';
}

/**
 * *Encryptes data
 *
 * @param data
 * @returns string
 * @date 05 January 2023
 * @developer Rahul Kundu
 */
export function encryption(data: string): string {
	const SECRET = appSettings.cryptoSecret;

	const b64 = CryptoJS.AES.encrypt(data, SECRET).toString();
	const e64 = CryptoJS.enc.Base64.parse(b64);
	const eHex = e64.toString(CryptoJS.enc.Hex);

	return eHex;
}

/**
 * *Decryptes encrypted string
 *
 * @param cipherText
 * @returns number
 * @date 05 January 2023
 * @developer Rahul Kundu
 */
export function decryption(cipherText: string): string {
	const SECRET = appSettings.cryptoSecret;

	const reb64 = CryptoJS.enc.Hex.parse(cipherText);
	const bytes = reb64.toString(CryptoJS.enc.Base64);
	const decrypt = CryptoJS.AES.decrypt(bytes, SECRET);
	const plain = decrypt.toString(CryptoJS.enc.Utf8);

	return plain;
}

/**
 * *Generating random ip address
 *
 * @returns string
 * @date 05 January 2023
 * @developer Rahul Kundu
 */
export function generateIPAddress(): string {
	return (
		Math.floor(Math.random() * 255) +
		1 +
		'.' +
		Math.floor(Math.random() * 255) +
		'.' +
		Math.floor(Math.random() * 255) +
		'.' +
		Math.floor(Math.random() * 255)
	);
}

/**
 * *Transform a number into a shorter representation with suffixes like K, M, B, and T,
 * *and with an optional prefix and specified number of decimal places.
 *
 * @param value - The number or string representation of a number to be transformed.
 * @param prefix - (Optional) The prefix to be added to the transformed number (e.g., currency symbol).
 * @param decimalPlaces - (Optional) The number of decimal places to include in the transformed number.
 * @returns A string representing the shorter form of the number with appropriate suffix, prefix, and decimal places.
 *
 * @date 19 February 2024
 * @developer Rahul Kundu
 */
export function shrinkNumber(
	value: number | string,
	prefix: string = '',
	decimalPlaces: number = 0
): string {
	// Define the suffixes for shorter representations
	const degrees = [
		{ sym: 'T', val: Math.pow(10, 12) },
		{ sym: 'B', val: Math.pow(10, 9) },
		{ sym: 'M', val: Math.pow(10, 6) },
		{ sym: 'k', val: 1000 }
	];

	// Define the rounder based on the number of decimal places
	const rounder = Math.pow(10, decimalPlaces);
	let abs = Math.abs(typeof value === 'number' ? value : parseFloat(value));
	let symbol = '';

	// Find the appropriate suffix for the number
	for (const degree of degrees) {
		const sample = abs / degree.val;
		const rounded = Math.round(sample * rounder) / rounder;
		if (rounded >= 1) {
			abs = rounded;
			symbol = degree.sym;
			break;
		}
	}

	// Format the number with the specified decimal places
	let formattedNumber = abs.toFixed(decimalPlaces);

	// Append zeroes to the formatted number if necessary
	if (decimalPlaces > 0 && !formattedNumber.includes('.')) {
		formattedNumber += '.';
		for (let i = 0; i < decimalPlaces; i++) {
			formattedNumber += '0';
		}
	}

	// Construct the final string with prefix, number, suffix, and sign
	return prefix + formattedNumber + symbol;
}
