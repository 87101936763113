import { Directive, HostListener } from '@angular/core';

@Directive({
	selector: '[preventSubmitOnEnter]'
})
export class PreventSubmitOnEnterDirective {
	/**
	 * *Listens to the keydown.enter event and prevents the default form submission behavior when the Enter key is pressed.
	 *
	 * @param event
	 * @date 23 June 2023
	 * @developer Rahul Kundu
	 */
	@HostListener('keydown.enter', ['$event'])
	onEnterKeydown(event: KeyboardEvent): void {
		event.preventDefault();
	}
}
