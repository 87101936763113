import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { IRawResponse, IResponse } from '@shared/models';
import {
	HttpEvent,
	HttpRequest,
	HttpHandler,
	HttpResponse,
	HttpInterceptor
} from '@angular/common/http';

@Injectable()
export class HttpSuccessInterceptor implements HttpInterceptor {
	constructor() {}

	/**
	 * *Intercepting success requests
	 *
	 * @date 05 December 2022
	 * @developer Rahul Kundu
	 */
	public intercept(
		request: HttpRequest<any>,
		next: HttpHandler
	): Observable<HttpEvent<IResponse>> {
		return next.handle(request).pipe(
			map((event: HttpEvent<any>) => {
				if (event instanceof HttpResponse) {
					const response = event.body as IRawResponse;
					const statusCode: number = event['status'];
					const dataset = response.result;
					const version = !!response.ver
						? response.ver
						: !!response.version
						? response.version
						: 0;

					return event.clone({
						body: {
							version,
							dataset,
							status: statusCode,
							jsonrpc: response.jsonrcp || '2.0',
							...(response.error && {
								error: response.error
							})
						}
					});
				}

				return event;
			})
		);
	}
}
