import * as Sentry from '@sentry/angular-ivy';
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
	enableProdMode();
}

Sentry.init({
	dsn: environment.sentryDsn,
	environment: environment.production ? 'production' : 'development',
	integrations: [
		// Registers and configures the Tracing integration,
		// which automatically instruments your application to monitor its
		// performance, including custom Angular routing instrumentation
		new Sentry.BrowserTracing({
			tracingOrigins: [environment.host, /^\//],
			routingInstrumentation: Sentry.routingInstrumentation
		}),
		// Registers the Replay integration,
		// which automatically captures Session Replays
		new Sentry.Replay()
	],
	// Set tracesSampleRate to 1.0 to capture 100%
	// of transactions for performance monitoring.
	// We recommend adjusting this value in production
	tracesSampleRate: environment.production ? 0.2 : 1.0,
	// Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
	tracePropagationTargets: [
		'localhost',
		environment.production
			? 'https://admin.craveble.com/'
			: 'https://refactor-admin-tpyq4t5pda-uc.a.run.app/'
	],
	// Capture Replay for 10% of all sessions,
	// plus for 100% of sessions with an error
	replaysSessionSampleRate: 0.1,
	replaysOnErrorSampleRate: 1.0,
	ignoreErrors: [
		'ResizeObserver loop limit exceeded',
		'ResizeObserver loop completed with undelivered notifications.'
	]
});

platformBrowserDynamic()
	.bootstrapModule(AppModule)
	.catch((err) => console.error(err));
